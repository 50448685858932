import i18next, { t } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Divider from '../Components/Divider';
import Contents from '../Content';
import DateSlider from '../PageComponents/HomePage/DateSlider';
import FindYourTeam from '../PageComponents/HomePage/FindYourTeam';
import HomeFooter from '../PageComponents/HomePage/HomeFooter';
import HomeSwiper from '../PageComponents/HomePage/HomeSwiper';
import HowWeHire from '../PageComponents/HomePage/HowWeHire';
// import Peoples from '../PageComponents/HomePage/Peoples';
import WhatWeOffer from '../PageComponents/HomePage/WhatWeOffer';
// import WhereWillYouWork from "../PageComponents/HomePage/WhereWillYouWork";

import Resources from '../Resources';

export default function Homepage() {
  return (
    <>
      <Helmet>
        <title>{t('meta.names.home')}</title>
        <meta name='title' content={t('meta.names.home')} />
        <meta
          name='keywords'
          content='Zaxe, Zaxe 3D, Zaxe Careers, Zaxe Jobs'
        />
        <meta name='description' content={t('meta.descs.home')} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://careers.zaxe.com/' />
        <meta property='og:title' content={t('meta.names.home')} />
        <meta property='og:description' content={t('meta.descs.home')} />
        <meta
          property='og:keywords'
          content='Zaxe, Zaxe 3D, Zaxe Careers, Zaxe Jobs'
        />
        <meta
          property='og:image'
          content='https://careers.zaxe.com/assets/images/OG/zxcogbanner.webp'
        />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://careers.zaxe.com/' />
        <meta property='twitter:site' content='@Zaxe3D' />
        <meta property='twitter:site:id' content='@Zaxe3D' />
        <meta property='twitter:creator' content='@Zaxe3D' />
        <meta property='twitter:creator:id' content='@Zaxe3D' />
        <meta property='twitter:title' content={t('meta.names.home')} />
        <meta property='twitter:description' content={t('meta.descs.home')} />
        <meta
          property='twitter:image'
          content='https://careers.zaxe.com/assets/images/OG/zxcogbanner.webp'
        />
        <link
          rel='alternate'
          hrefLang='tr'
          href='https://careers.zaxe.com/tr/'
        />
        <link rel='alternate' hrefLang='en' href='https://careers.zaxe.com/' />
      </Helmet>
      <div
        className='relative w-full h-screen p-0 m-0 bg-center bg-cover font-mark'
        style={{ backgroundImage: `url(${Resources.Banner})` }}>
        <div className=' w-full h-full grid grid-cols-1 justify-items-center place-content-center gap-y-1 bg-black bg-opacity-[0.37]'>
          <h1 className='font-normal text-white uppercase xl:text-lg lg:text-lg text-md'>
            {t('pages.homepage.sections.top-banner.first-header')}
          </h1>
          <h1 className='w-full max-w-4xl p-3 text-xl font-bold text-center text-white xl:text-3xl lg:text-5xl'>
            {t('pages.homepage.sections.top-banner.second-header')}
          </h1>
          <Link
            className='p-1 px-8 text-sm font-bold text-center text-white transition-all duration-200 rounded-full bg-zaxe hover:bg-black xl:text-lg lg:text-lg xl:mt-10 lg:mt-10 mt-7'
            to={Contents.header.navigation.navArray[1].links[i18next.language]}>
            {t('buttons.view-open-roles')}
          </Link>
        </div>
      </div>
      <HomeSwiper />
      <FindYourTeam />
      <Divider classNames='py-16' />
      <HowWeHire />
      <Divider classNames='py-16' />
      {/* <WhereWillYouWork /> */}
      <Divider classNames='py-16' />
      <WhatWeOffer />
      <Divider classNames='py-16' />
      <DateSlider />
      {/* <Peoples /> */}
      <HomeFooter />
    </>
  );
}
