import { t } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet';
import Divider from '../Components/Divider';
import HomeFooter from '../PageComponents/HomePage/HomeFooter';
import HowWeHire from '../PageComponents/HomePage/HowWeHire';
import WhatWeOffer from '../PageComponents/HomePage/WhatWeOffer';
import JobTable from '../PageComponents/OpenRoles/JobTable';
import Resources from '../Resources';

export default function OpenRoles() {
  return (
    <>
      <Helmet>
        <title>{t('meta.names.open-roles')}</title>
        <meta name='title' content={t('meta.names.open-roles')} />
        <meta
          name='keywords'
          content='Zaxe, Zaxe 3D, Zaxe Careers, Zaxe Jobs'
        />
        <meta name='description' content={t('meta.descs.open-roles')} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://careers.zaxe.com/open-roles' />
        <meta property='og:title' content={t('meta.names.open-roles')} />
        <meta property='og:description' content={t('meta.descs.open-roles')} />
        <meta
          property='og:keywords'
          content='Zaxe, Zaxe 3D, Zaxe Careers, Zaxe Jobs'
        />
        <meta
          property='og:image'
          content='https://careers.zaxe.com/assets/images/OG/zxcogbanner.webp'
        />
        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content='https://careers.zaxe.com/open-roles'
        />
        <meta property='twitter:site' content='@Zaxe3D' />
        <meta property='twitter:site:id' content='@Zaxe3D' />
        <meta property='twitter:creator' content='@Zaxe3D' />
        <meta property='twitter:creator:id' content='@Zaxe3D' />
        <meta property='twitter:title' content={t('meta.names.open-roles')} />
        <meta
          property='twitter:description'
          content={t('meta.descs.open-roles')}
        />
        <meta
          property='twitter:image'
          content='https://careers.zaxe.com/assets/images/OG/zxcogbanner.webp'
        />
        <link
          rel='alternate'
          hrefLang='tr'
          href='https://careers.zaxe.com/tr/open-roles'
        />
        <link
          rel='alternate'
          hrefLang='en'
          href='https://careers.zaxe.com/open-roles'
        />
      </Helmet>
      <div
        className='font-mark min-h-[50vh] relative w-full  grid grid-cols-1 justify-center
        p-0 m-0 bg-cover bg-center'
        style={{ backgroundImage: `url(${Resources.Banner})` }}>
        <div className=' w-full h-full grid grid-cols-1 justify-items-center place-content-center gap-y-1 bg-black bg-opacity-[0.37]'>
          <h1 className='text-white uppercase font-extralight xl:text-lg lg:text-lg text-md'>
            {t('pages.homepage.sections.top-banner.first-header')}
          </h1>
          <h1 className='text-xl font-bold text-white xl:text-5xl lg:text-5xl'>
            {t('header.navigation.open-roles')}
          </h1>
        </div>
      </div>
      <JobTable />
      <HowWeHire />
      <Divider classNames='py-10' />
      <WhatWeOffer />
      <Divider classNames='py-10' />
      <HomeFooter />
    </>
  );
}
