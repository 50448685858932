import ZaxeLogoWhite from "./Images/Main/zaxelogo-white.svg";
import ZaxeLogoJustWhite from "./Images/Main/zaxelogo-justwhite.svg";
import ZaxeLogoBlack from "./Images/Main/zaxelogo-black.svg";

import Banner from "./Images/Mix/banner.webp";

/* Main Slider */
import MainSliderAgility from "./Images/MainSlider/agility.webp";
import MainSliderPassion from "./Images/MainSlider/passion.webp";
import MainSliderTrust from "./Images/MainSlider/trust.webp";

/* Find Your Team */
import FYT_EngineeringProduct from "./Images/FindYourTeam/engineering-product.webp";
import FYT_InternalOperations from "./Images/FindYourTeam/internal-operations.webp";
import FYT_Internships from "./Images/FindYourTeam/internships.webp";
import FYT_Marketing from "./Images/FindYourTeam/marketing.webp";
import FYT_SalesServices from "./Images/FindYourTeam/sales-services.webp";
import FYT_Manufacturing from "./Images/FindYourTeam/manufacturing.webp";

/* Timeline */
import Zaxe2015 from "./Images/Timeline/zaxe-2015.webp";
import Zaxe2016 from "./Images/Timeline/zaxe-2016.webp";
import Zaxe2017 from "./Images/Timeline/zaxe-2017.webp";
import Zaxe2018 from "./Images/Timeline/zaxe-2018.webp";
import Zaxe2019 from "./Images/Timeline/zaxe-2019.webp";
import Zaxe2020 from "./Images/Timeline/zaxe-2020.webp";
import Zaxe2021 from "./Images/Timeline/zaxe-2021.webp";
import Zaxe2022 from "./Images/Timeline/zaxe-2022.webp";

/* Icons */
import ChallengeIcon from "./Images/Icons/challenge.svg";
import ImpactIcon from "./Images/Icons/impact.svg";
import LearnIcon from "./Images/Icons/learn.svg";
import WorkspaceIcon from "./Images/Icons/workspace.svg";

/* Where Will You Work */
import WWYK_Banner from "./Images/WhereWillYouWork/where-will-you-work.webp";

const Resources = {
  ZaxeLogoWhite,
  ZaxeLogoJustWhite,
  ZaxeLogoBlack,
  Banner,

  mainSlider: {
    MainSliderAgility,
    MainSliderPassion,
    MainSliderTrust,
  },

  timeline: {
    Zaxe2015,
    Zaxe2016,
    Zaxe2017,
    Zaxe2018,
    Zaxe2019,
    Zaxe2020,
    Zaxe2021,
    Zaxe2022,
  },

  icons: {
    ChallengeIcon,
    ImpactIcon,
    LearnIcon,
    WorkspaceIcon,
  },

  /* Find Your Team */
  FYT_EngineeringProduct,
  FYT_InternalOperations,
  FYT_Internships,
  FYT_Marketing,
  FYT_SalesServices,
  FYT_Manufacturing,

  /* Where Will You Work */
  WWYK_Banner,
};

export default Resources;
