/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import i18next from 'i18next';
import PDFEN from '../../PDF/calisanAdaylariOnayMetniEN.txt';
import PDFTR from '../../PDF/calisanAdaylariOnayMetniTR.txt';

function RecruitmenCandidatesConsent() {
  const [language, setLanguage] = React.useState(i18next.language || 'en');
  const [currentPDF, setCurrentPDF] = React.useState(null);
  const PDFS = { en: PDFEN, tr: PDFTR };

  React.useEffect(() => {
    fetch(PDFS[language])
      .then((response) => response.text())
      .then((text) => setCurrentPDF(text));
  }, [language]);

  return (
    <div className='w-full bg-zaxe min-h-screen grid grid-cols-1 place-content-start place-items-center py-[15vh] px-5'>
      <div className='flex items-center justify-start w-full max-w-5xl px-0 py-2'>
        <button
          className='p-3 py-2 text-black transition-all duration-100 bg-white rounded-md hover:bg-black hover:text-white'
          onClick={() => setLanguage(language === 'en' ? 'tr' : 'en')}
          type='button'>
          {language === 'en' ? 'TR' : 'EN'}
        </button>
      </div>
      <div
        className='w-full max-w-5xl p-5 bg-white rounded-md law-body'
        dangerouslySetInnerHTML={{ __html: currentPDF }}></div>
    </div>
  );
}

export default RecruitmenCandidatesConsent;
