/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Resources from '../Resources';
import i18next, { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import GooglePicker from 'react-google-picker';
import CareersContext from '../Contexts/CareersContext';
import JobFooter from '../PageComponents/JobApply/FooterMap';

import { FaGoogleDrive } from 'react-icons/fa';
import { BiErrorCircle, BiTrash } from 'react-icons/bi';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { AiOutlineCheckCircle, AiOutlineLoading } from 'react-icons/ai';

export default function JobApply() {
  const { id } = useParams();
  const careerForm = React.useRef();
  const isMounted = React.useRef(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { careersData } = React.useContext(CareersContext);

  const [selectedJob, setSelectedJob] = React.useState(null);
  const [careerBody, setCareerBody] = React.useState({
    person: {},
    role: {},
  });

  const sendCarrierForm = async (e) => {
    e.preventDefault();
    setPopupStatus({
      ...popupStatus,
      visible: true,
      status: 'loading',
      text: 'loading',
    });

    await fetch(`${apiUrl}/careerRequest`, {
      method: 'POST',
      body: JSON.stringify(careerBody),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPopupStatus({
          ...popupStatus,
          visible: true,
          status: 'success',
          text: 'success',
        });
      })
      .catch((err) => {
        setPopupStatus({
          ...popupStatus,
          visible: true,
          status: 'error',
          text: 'error',
        });
      });
  };

  React.useEffect(() => {
    if (careersData) {
      setSelectedJob(careersData.find((role) => role.id === id) || false);
    }
  }, [id, careersData]);

  const handleFormChange = (element) => {
    setCareerBody({
      ...careerBody,
      person: {
        ...careerBody.person,
        [element.currentTarget.name]: element.currentTarget.value,
      },
    });
  };

  React.useEffect(() => {
    careerForm.current &&
      careerForm.current.addEventListener('submit', sendCarrierForm);
    return () =>
      careerForm.current.removeEventListener('submit', sendCarrierForm);
  }, [careerBody]);

  React.useEffect(() => {
    if (selectedJob === false)
      window.location.replace(`/${i18next.language}/404`);
    if (isMounted.current) {
      setCareerBody({
        ...careerBody,
        role: {
          id: selectedJob && selectedJob.id,
          title: selectedJob && selectedJob.title,
          team: selectedJob && selectedJob.team.name,
          location: selectedJob && selectedJob.location.name,
          type: selectedJob && selectedJob.type.name,
        },
      });
    } else {
      isMounted.current = true;
    }
  }, [selectedJob]);

  const Spacer = () => {
    return (
      <div className='w-full my-4 min-h-[1px] max-h-[1px] bg-zinc-300 p-0 m-0' />
    );
  };

  const Icons = {
    loading: (
      <AiOutlineLoading className='p-1 rounded-full text-sky-500 bg-sky-400 animate-spin bg-opacity-30' />
    ),
    success: (
      <AiOutlineCheckCircle className='p-1 rounded-full text-lime-500 bg-lime-400 bg-opacity-30' />
    ),
    error: (
      <BiErrorCircle className='p-1 text-red-500 bg-red-400 rounded-full bg-opacity-30' />
    ),
  };

  const Texts = {
    loading: t('popups.form-sending'),
    success: t('popups.form-sent'),
    error: t('popups.form-error'),
    onlyPdf: t('popups.only-pdf'),
    googleAuthFailed: 'Google picker event failed! Help : webdev@zaxe.com',
  };

  const [popupStatus, setPopupStatus] = React.useState({
    visible: false,
    status: 'loading',
    text: 'loading',
  });

  const shortenText = (str) => {
    if (str.length > 12) {
      return `${str.slice(0, 12)}..`;
    }
    return str;
  };

  const Popup = (
    <div
      onClick={() => {
        popupStatus.status !== 'loading' &&
          setPopupStatus({ ...popupStatus, visible: false });
      }}
      className={`${
        popupStatus.visible ? 'visible opacity-100' : 'invisible opacity-0'
      } w-full h-screen transition-all duration-300 fixed bg-black bg-opacity-70 z-[999] grid grid-cols-1 place-content-center justify-items-center`}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='w-full max-w-[30rem] p-5 bg-transparent'>
        <div
          className={`${
            popupStatus.visible ? 'scale-100' : 'scale-150'
          } font-mark transition-all duration-300 w-full min-h-[20rem] bg-white rounded-xl gap-5 shadow-xl p-5 grid grid-cols-1 place-content-center justify-items-center`}>
          <div className='flex content-center justify-center w-full text-4xl'>
            {Icons[popupStatus.status]}
          </div>
          <div className='flex flex-wrap content-center justify-center w-full max-w-xs p-0 px-5 py-5 m-0 text-center text-zinc-500 text-md'>
            {Texts[popupStatus.text]}
          </div>
          <div className='flex flex-wrap content-center justify-center w-full p-0 m-0 text-center'>
            <button
              onClick={() =>
                popupStatus.loading !== 'loading' &&
                setPopupStatus({ ...popupStatus, visible: false })
              }
              disabled={popupStatus.status === 'loading' && true}
              className='bg-zaxe/10 text-zaxe hover:bg-zaxe/30 w-full p-2 max-w-[7rem] rounded-md disabled:bg-zinc-500/10 disabled:text-zinc-500 disabled:hover:bg-zinc-500/20'>
              {t('buttons.okay')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>
          {selectedJob
            ? `${selectedJob.title} | ${t('meta.names.job-apply')}`
            : t('meta.names.home')}
        </title>
        <meta
          name='title'
          content={
            selectedJob
              ? `${selectedJob.title} | ${t('meta.names.job-apply')}`
              : t('meta.names.home')
          }
        />
        <meta
          name='keywords'
          content='Zaxe, Zaxe 3D, Zaxe Careers, Zaxe Jobs'
        />
        <meta name='description' content={t('meta.descs.job-apply')} />
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content={
            selectedJob
              ? `https://careers.zaxe.com/job/${selectedJob.id}/apply`
              : 'https://careers.zaxe.com/open-roles'
          }
        />
        <meta
          property='og:title'
          content={
            selectedJob
              ? `${selectedJob.title} | ${t('meta.names.job-apply')}`
              : t('meta.names.home')
          }
        />
        <meta property='og:description' content={t('meta.descs.job-apply')} />
        <meta
          property='og:keywords'
          content='Zaxe, Zaxe 3D, Zaxe Careers, Zaxe Jobs'
        />
        <meta
          property='og:image'
          content='https://careers.zaxe.com/assets/images/OG/zxcogbanner.webp'
        />
        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content={
            selectedJob
              ? `https://careers.zaxe.com/job/${selectedJob.id}/apply`
              : 'https://careers.zaxe.com/open-roles'
          }
        />
        <meta property='twitter:site' content='@Zaxe3D' />
        <meta property='twitter:site:id' content='@Zaxe3D' />
        <meta property='twitter:creator' content='@Zaxe3D' />
        <meta property='twitter:creator:id' content='@Zaxe3D' />
        <meta
          property='twitter:title'
          content={
            selectedJob
              ? `${selectedJob.title} | ${t('meta.names.job-apply')}`
              : t('meta.names.home')
          }
        />
        <meta
          property='twitter:description'
          content={t('meta.descs.job-apply')}
        />
        <meta
          property='twitter:image'
          content='https://careers.zaxe.com/assets/images/OG/zxcogbanner.webp'
        />
        <link
          rel='alternate'
          hrefLang='tr'
          href={
            selectedJob
              ? `https://careers.zaxe.com/tr/job/${selectedJob.id}/apply`
              : 'https://careers.zaxe.com/tr/open-roles'
          }
        />
        <link
          rel='alternate'
          hrefLang='en'
          href={
            selectedJob
              ? `https://careers.zaxe.com/job/${selectedJob.id}/apply`
              : 'https://careers.zaxe.com/open-roles'
          }
        />
      </Helmet>
      {Popup}
      <div
        className={`${
          selectedJob !== null ? '!opacity-0 !invisible' : null
        } transition-all duration-700 fixed left-0 top-0 z-[99999999] w-full h-full bg-white opacity-100 visible flex flex-wrap justify-center content-center`}>
        <div className='rounded-full text-zaxe fill-current shadow-[0_0px_10px_#009bde7c] '>
          <svg
            className={`animate-spin h-5 w-5`}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'>
            <circle
              className='opacity-50'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'></circle>
            <path
              className='opacity-100'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
          </svg>
        </div>
      </div>
      {selectedJob && (
        <>
          <div
            className='font-mark min-h-[50vh] relative w-full  grid grid-cols-1 justify-center
        p-0 m-0 bg-cover bg-center'
            style={{ backgroundImage: `url(${Resources.Banner})` }}>
            <div className='pt-20 w-full h-full grid grid-cols-1 justify-items-center place-content-center gap-y-0 bg-black bg-opacity-[0.37]'>
              <h1 className='text-white uppercase font-extralight xl:text-lg lg:text-lg text-md'>
                {t('pages.homepage.sections.top-banner.first-header')}
              </h1>
              <h1 className='pb-5 text-xl font-bold text-white xl:text-5xl lg:text-5xl'>
                {selectedJob.title}
              </h1>
              <h3 className='text-xs font-light text-white xl:text-sm lg:text-sm'>
                {selectedJob.type.name}
              </h3>
            </div>
          </div>
          <div className='flex flex-wrap content-start justify-center w-full p-0 m-0 overflow-hidden font-mark'>
            <div className='grid w-full max-w-xl grid-cols-12 xl:max-w-7xl lg:max-w-7xl xl:place-items-start lg:place-items-start place-items-center place-content-start'>
              <div className='grid w-full grid-cols-1 bg-white xl:col-span-8 lg:col-span-8 col-span-full gap-y-5 place-content-start place-items-start'>
                <div className='w-full max-w-[45rem] xl:p-10 lg:p-10 p-5 gap-y-5 grid grid-cols-1 place-content-start place-items-start'>
                  <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
                    {t('pages.job-apply.headers.working-at-zaxe')}
                  </h1>
                  <p className='w-full text-sm font-normal xl:text-md lg:text-md text-zinc-500'>
                    {t('pages.job-apply.default-texts.working-at-zaxe')}
                  </p>
                </div>
                <div className='w-full max-w-[45rem] xl:p-10 lg:p-10 p-5 gap-y-5 grid grid-cols-1 place-content-start place-items-start'>
                  <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
                    {t('pages.job-apply.headers.about-this-job')}
                  </h1>
                  <p className='w-full text-sm font-normal whitespace-pre-wrap xl:text-md lg:text-md text-zinc-500'>
                    {selectedJob.descriptions.aboutJob}
                  </p>
                </div>
                <div className='w-full max-w-[45rem]  xl:p-10 lg:p-10 p-5 gap-y-5 grid grid-cols-1 place-content-start place-items-start'>
                  <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
                    {t('pages.job-apply.headers.what-we-expect')}
                  </h1>
                  <ul className='grid w-full grid-cols-1 place-items-start place-content-start gap-y-5'>
                    {selectedJob.descriptions['section-1'].values.map(
                      (element, index) => (
                        <li
                          key={`jobSect1Data${index}`}
                          className='relative w-full text-sm font-normal xl:text-md lg:text-md text-zinc-500'>
                          <span className='absolute w-2 h-2 -left-4 top-1 bg-zaxe' />
                          {element.value}
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className='w-full max-w-[45rem]  xl:p-10 lg:p-10 p-5 gap-y-5 grid grid-cols-1 place-content-start place-items-start'>
                  <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
                    {t('pages.job-apply.headers.you-will-deal-with')}
                  </h1>
                  <ul className='grid w-full grid-cols-1 place-items-start place-content-start gap-y-5'>
                    {selectedJob.descriptions['section-2'].values.map(
                      (element, index) => (
                        <li
                          key={`jobSect2Data${index}`}
                          className='relative w-full text-sm font-normal xl:text-md lg:text-md text-zinc-500'>
                          <span className='absolute w-2 h-2 -left-4 top-1 bg-zaxe' />
                          {element.value}
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className='w-full max-w-[45rem] xl:p-10 lg:p-10 p-5 gap-y-5 grid grid-cols-1 place-content-start place-items-start'>
                  <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
                    {t('pages.job-apply.headers.we-will-you-work')}
                  </h1>
                  <p className='w-full text-sm font-normal xl:text-md lg:text-md text-zinc-500'>
                    {t('pages.job-apply.default-texts.fourth-section')}
                  </p>
                  <img
                    className='w-full'
                    src={Resources.FYT_InternalOperations}
                    alt='Zaxe Careers'
                  />
                </div>
                <div className='w-full max-w-[45rem]  xl:p-10 lg:p-10 p-5 gap-y-5 grid grid-cols-1 place-content-start place-items-start'>
                  <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
                    {t('pages.job-apply.headers.we-offer')}
                  </h1>
                  <ul className='grid w-full grid-cols-1 place-items-start place-content-start gap-y-5'>
                    {selectedJob.descriptions['section-3'].values.map(
                      (element, index) => (
                        <li
                          key={`jobSect3Data${index}`}
                          className='relative w-full text-sm font-normal xl:text-md lg:text-md text-zinc-500'>
                          <span className='absolute w-2 h-2 -left-4 top-1 bg-zaxe' />
                          {element.value}
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className='w-full max-w-[45rem] xl:p-10 lg:p-10 p-5 gap-y-5 grid grid-cols-1 place-content-start place-items-start'>
                  <p className='w-full text-sm font-normal xl:text-md lg:text-md text-zinc-500'>
                    {t('pages.job-apply.default-texts.last-section')}
                  </p>
                </div>
              </div>
              <div className='w-full h-full max-w-lg py-5 xl:col-span-4 lg:col-span-4 col-span-full xl:py-10 lg:py-10'>
                <div className='w-full bg-zinc-200'>
                  <form
                    ref={careerForm}
                    className='grid w-full grid-cols-1 p-5 text-left gap-y-6 place-content-start place-items-start xl:p-10 lg:p-10'>
                    <div className='grid w-full grid-cols-8 place-content-start place-items-between'>
                      <label
                        htmlFor='firstName'
                        className='col-span-3 text-zinc-500 text-md'>
                        First Name
                        <span className='text-red-500 text-md opacity-70'>
                          *
                        </span>
                      </label>
                      <input
                        onChange={(e) => {
                          e.target.value = `${e.target.value[0].toUpperCase()}${e.target.value.slice(
                            1
                          )}`;
                          handleFormChange(e);
                        }}
                        required
                        type='text'
                        pattern='([\sa-zA-ZöçşüğıÖÇŞÜĞI\s]+){2,}'
                        id='firstName'
                        name='firstName'
                        className='min-h-[2rem] col-span-5 transition-all duration-150 px-2 text-sm text-zinc-600 valid:focus:ring-zaxe focus:ring-4 focus:ring-pink-600 border-0 py-0 ring-0 focus:invalid:ring-offset-pink-600 !outline-none'></input>
                    </div>
                    <div className='grid w-full grid-cols-8 place-content-start place-items-between'>
                      <label
                        htmlFor='surname'
                        className='col-span-3 text-zinc-500 text-md'>
                        Surname
                        <span className='text-red-500 text-md opacity-70'>
                          *
                        </span>
                      </label>
                      <input
                        onChange={(e) => {
                          e.target.value = `${e.target.value[0].toUpperCase()}${e.target.value.slice(
                            1
                          )}`;
                          handleFormChange(e);
                        }}
                        required
                        type='text'
                        pattern='([\sa-zA-ZöçşüğıÖÇŞÜĞI\s]+){2,}'
                        id='surname'
                        name='surname'
                        className='min-h-[2rem] col-span-5 transition-all duration-150 px-2 text-sm text-zinc-600 valid:focus:ring-zaxe focus:ring-4 focus:ring-pink-600 border-0 py-0 ring-0 focus:invalid:ring-offset-pink-600 !outline-none'></input>
                    </div>
                    <div className='grid w-full grid-cols-8 place-content-start place-items-between'>
                      <label
                        htmlFor='phoneNumber'
                        className='col-span-3 text-zinc-500 text-md'>
                        Phone
                        <span className='text-red-500 text-md opacity-70'>
                          *
                        </span>
                      </label>
                      <input
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]+/g,
                            ''
                          );
                          handleFormChange(e);
                        }}
                        required
                        type='tel'
                        maxLength={20}
                        pattern='[0-9]{10,15}'
                        id='phoneNumber'
                        name='phoneNumber'
                        className='min-h-[2rem] col-span-5 transition-all duration-150 px-2 text-sm text-zinc-600 focus:valid:ring-zaxe focus:ring-4 focus:ring-pink-600 border-0 py-0 ring-0 focus:invalid:ring-pink-600 !outline-none'></input>
                    </div>
                    <div className='grid w-full grid-cols-8 place-content-start place-items-between'>
                      <label
                        htmlFor='emailAddress'
                        className='col-span-3 text-zinc-500 text-md'>
                        E-Mail
                        <span className='text-red-500 text-md opacity-70'>
                          *
                        </span>
                      </label>
                      <input
                        data-val='personEmail'
                        onChange={(e) => handleFormChange(e)}
                        required
                        type='email'
                        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                        id='emailAddress'
                        name='emailAddress'
                        className='min-h-[2rem] col-span-5 transition-all duration-150 px-2 text-sm text-zinc-600 valid:focus:ring-zaxe focus:ring-4 focus:ring-pink-600 border-0 py-0 ring-0 focus:invalid:ring-offset-pink-600 !outline-none'></input>
                    </div>
                    <div className='grid w-full grid-cols-8 place-content-start place-items-between'>
                      <label
                        htmlFor='address'
                        className='col-span-3 text-zinc-500 text-md'>
                        Address
                        <span className='text-red-500 text-md opacity-70'>
                          *
                        </span>
                      </label>
                      <input
                        data-val='address'
                        onChange={(e) => handleFormChange(e)}
                        required
                        type='text'
                        pattern='{5,}'
                        id='address'
                        name='address'
                        className='min-h-[2rem] col-span-5 transition-all duration-150 px-2 text-sm text-zinc-600 valid:focus:ring-zaxe focus:ring-4 focus:ring-pink-600 border-0 py-0 ring-0 focus:invalid:ring-offset-pink-600 !outline-none'></input>
                    </div>
                    <Spacer />
                    {selectedJob.inputs.map(
                      (input) =>
                        input.isActive &&
                        (input.type === 'url' ? (
                          <div
                            key={`personDynamicInput${input.slug}`}
                            className='grid w-full grid-cols-8 place-content-start place-items-between'>
                            <label
                              htmlFor={`person-${input.slug}`}
                              className='col-span-3 text-zinc-500 text-md'>
                              {input.label}
                            </label>
                            <input
                              onChange={(e) => handleFormChange(e)}
                              type='url'
                              id={`person-${input.slug}`}
                              name={`${input.slug}`}
                              placeholder='https://'
                              className='min-h-[2rem] placeholder:text-zinc-400 col-span-5 transition-all duration-150 px-2 text-sm text-zinc-600 valid:focus:ring-zaxe focus:ring-4 focus:ring-pink-600 border-0 py-0 ring-0 focus:invalid:ring-offset-pink-600 !outline-none'></input>
                          </div>
                        ) : input.type === 'file' ? (
                          <div
                            key={`personDynamicInput${input.slug}`}
                            className='grid w-full grid-cols-8 place-content-start place-items-between'>
                            <div className='relative grid grid-cols-1 col-span-3 place-content-start place-items-start text-zinc-500 text-md'>
                              <span>{input.label}</span>
                              {careerBody && careerBody.person[input.slug] && (
                                <div className='text-xs flex justify-start gap-1 items-center absolute -bottom-3.5 left-0'>
                                  <span>
                                    {shortenText(
                                      careerBody.person[input.slug].name
                                    )}
                                  </span>
                                  <button
                                    type='button'
                                    className='p-0 m-0 overflow-hidden rounded-sm'
                                    onClick={() => {
                                      setCareerBody({
                                        ...careerBody,
                                        person: {
                                          ...careerBody.person,
                                          [input.slug]: null,
                                        },
                                      });
                                      document.querySelector(
                                        `#person-${input.slug}`
                                      ).value = '';
                                    }}>
                                    <BiTrash className='w-3.5 h-3.5 hover:bg-black/20 hover:text-black p-px rounded-sm' />
                                  </button>
                                </div>
                              )}
                            </div>
                            <input
                              data-val='personResume'
                              onChange={(e) => {
                                if (
                                  e.target.files.length > 0 &&
                                  e.target.files[0].name
                                    .slice(-3)
                                    .toUpperCase() !== 'PDF'
                                ) {
                                  setCareerBody({
                                    ...careerBody,
                                    person: {
                                      ...careerBody.person,
                                      [input.slug]: null,
                                    },
                                  });
                                  e.target.value = '';
                                  return setPopupStatus({
                                    ...popupStatus,
                                    visible: true,
                                    status: 'error',
                                    text: 'onlyPdf',
                                  });
                                }

                                if (e.target.files.length > 0) {
                                  const reader = new FileReader();
                                  reader.addEventListener(
                                    'load',
                                    (convertedFile) => {
                                      setCareerBody({
                                        ...careerBody,
                                        person: {
                                          ...careerBody.person,
                                          [input.slug]: {
                                            type: 'attach',
                                            name: e.target.files[0].name,
                                            blob: convertedFile.target.result,
                                          },
                                        },
                                      });
                                    }
                                  );
                                  reader.readAsDataURL(e.target.files[0]);
                                }
                              }}
                              type='file'
                              id={`person-${input.slug}`}
                              name={`${input.slug}`}
                              className='hidden'
                            />
                            <div className='flex items-center justify-between w-full col-span-5 gap-1 text-xs'>
                              <button
                                type='button'
                                className='flex items-center justify-center gap-1 font-normal text-zaxe hover:text-black'
                                onClick={() =>
                                  document
                                    .querySelector(`input[name=${input.slug}]`)
                                    .click()
                                }>
                                <span>Attach</span>
                                <IoDocumentAttachOutline className='w-4 h-4' />
                              </button>
                              <span className='text-xs text-zinc-400'>or</span>
                              <GooglePicker
                                clientId={
                                  process.env.REACT_APP_GOOGLE_API_CLIENT_ID
                                }
                                developerKey={
                                  process.env
                                    .REACT_APP_GOOGLE_CARRIER_PICKER_API_KEY
                                }
                                onChange={(data) => {
                                  if (data.docs && data.docs.length > 0) {
                                    setCareerBody({
                                      ...careerBody,
                                      person: {
                                        ...careerBody.person,
                                        [input.slug]: {
                                          type: 'drive',
                                          name: data.docs[0].name,
                                          url: data.docs[0].embedUrl,
                                        },
                                      },
                                    });
                                  }
                                }}
                                scope={[
                                  'https://www.googleapis.com/auth/drive.readonly',
                                ]}
                                onAuthFailed={() =>
                                  setPopupStatus({
                                    ...popupStatus,
                                    visible: true,
                                    status: 'error',
                                    text: 'googleAuthFailed',
                                  })
                                }
                                multiselect={false}
                                viewId={'DOCUMENTS'}>
                                <button
                                  type='button'
                                  className='flex items-center justify-center gap-1 font-normal text-zaxe hover:text-black '>
                                  <span>Google Drive</span>
                                  <FaGoogleDrive className='w-3.5 h-3.5' />
                                </button>
                              </GooglePicker>
                            </div>
                          </div>
                        ) : null)
                    )}

                    <Spacer />
                    <div className='grid w-full grid-cols-1 gap-3 place-content-start place-items-between'>
                      <label
                        htmlFor='legallyWorkInTurkey'
                        className='max-w-xs text-zinc-500 text-md'>
                        Are you legally eligible to work in the Turkey for Zaxe?
                        <span className='text-red-500 text-md opacity-70'>
                          *
                        </span>
                      </label>
                      <select
                        defaultValue={''}
                        onChange={(e) => handleFormChange(e)}
                        required
                        id='legallyWorkInTurkey'
                        name='legallyWorkInTurkey'
                        className='min-h-[2rem] transition-all w-full duration-150 px-2 text-sm text-zinc-600 valid:focus:ring-zaxe focus:ring-4 focus:ring-pink-600 border-0 py-0 ring-0 focus:invalid:ring-offset-pink-600 !outline-none'>
                        <option value='' disabled>
                          Pleace select
                        </option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                      </select>
                    </div>
                    <div className='grid w-full grid-cols-1 gap-3 place-content-start place-items-between'>
                      <label
                        htmlFor='visaEligibility'
                        className='max-w-xs text-zinc-500 text-md'>
                        Is your eligibility based on a work visa?
                        <span className='text-red-500 text-md opacity-70'>
                          *
                        </span>
                      </label>
                      <select
                        defaultValue={''}
                        onChange={(e) => handleFormChange(e)}
                        required
                        id='visaEligibility'
                        name='visaEligibility'
                        className='min-h-[2rem] transition-all w-full duration-150 px-2 text-sm text-zinc-600 valid:focus:ring-zaxe focus:ring-4 focus:ring-pink-600 border-0 py-0 ring-0 focus:invalid:ring-offset-pink-600 !outline-none'>
                        <option value='' disabled>
                          Pleace select
                        </option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                      </select>
                    </div>
                    <Spacer />
                    <div className='flex content-start justify-start w-full gap-2'>
                      <input
                        id='cf-terms'
                        className='rounded-sm relative top-1 transition-all duration-150 border-zaxe/30 border-[1px] cursor-pointer checked:!bg-zaxe !outline-0'
                        type={'checkbox'}
                        required
                      />
                      {i18next.language === 'en' ? (
                        <label
                          htmlFor='cf-terms'
                          className='max-w-xs text-zinc-500 text-md'>
                          I agree to the{' '}
                          <Link
                            to='/statement-of-disclosure-for-recruitmen-candidates'
                            className='text-zaxe hover:text-sky-400'>
                            Statement of Disclosure for Recruitmen Candidates{' '}
                          </Link>
                          and
                          <Link
                            to='/recruitment-candidates-consent-text'
                            className='text-zaxe hover:text-sky-400'>
                            {' '}
                            Recruitment Candidates Consent Text
                          </Link>
                          .
                        </label>
                      ) : (
                        <label
                          htmlFor='cf-terms'
                          className='max-w-xs text-zinc-500 text-md'>
                          <Link
                            to='/tr/statement-of-disclosure-for-recruitmen-candidates'
                            className='text-zaxe hover:text-sky-400'>
                            Çalışan Adayları Kişisel Verilerin İşlenmesi Metni{' '}
                          </Link>
                          ve
                          <Link
                            to='/tr/recruitment-candidates-consent-text'
                            className='text-zaxe hover:text-sky-400'>
                            {' '}
                            Çalışan Adayları Rıza Metni
                          </Link>
                          'ni okudum, kabul ediyorum.
                        </label>
                      )}
                    </div>
                    <div className='flex content-center justify-start w-full p-0 m-0'>
                      <button className='relative w-full max-w-[13rem] p-2 py-1 bg-zaxe group text-white font-normal hover:bg-black transition-all duration-150 text-md'>
                        Submit Application
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <JobFooter />
    </>
  );
}
