/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import i18next, { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import CareersContext from '../../Contexts/CareersContext';

export default function JobTable() {
  const [jobTeams, setJobTeam] = useState(null);
  const [selectedCategory, setCategory] = useState(null);
  const { careersData } = useContext(CareersContext);

  useEffect(() => {
    const backupRoleTeams = [];
    const openRoleTeams = [];
    careersData && careersData.map(({ team }) => backupRoleTeams.push(team));
    backupRoleTeams.map(
      (bTeam) =>
        openRoleTeams.filter(({ slug }) => slug === bTeam.slug).length === 0 &&
        openRoleTeams.push({
          name: bTeam.name,
          slug: bTeam.slug,
          count: backupRoleTeams.filter(({ slug }) => slug === bTeam.slug)
            .length,
        })
    );
    setJobTeam(openRoleTeams);
  }, [careersData]);

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let urlCategory = params
      ? params.get('category') && params.get('category').toLowerCase()
      : null;
    if (urlCategory) {
      urlCategory =
        urlCategory[0] + urlCategory.slice(1, urlCategory.length).toLowerCase();
      if (
        urlCategory &&
        jobTeams &&
        jobTeams.filter(({ slug }) => slug === urlCategory).length > 0
      ) {
        setCategory(jobTeams.find(({ slug }) => slug === urlCategory));
      }
    }
  }, [jobTeams]);

  return (
    <div className='flex justify-center w-full p-5 bg-white font-mark py-14'>
      <div className='w-full max-w-[60rem] flex flex-wrap justify-center content-start'>
        <div className='relative grid w-full grid-cols-10 p-0 m-0'>
          <div
            className={`${
              careersData !== null && jobTeams !== null
                ? '!opacity-0 !invisible'
                : null
            } transition-all duration-700 absolute left-0 top-0 z-[11] w-full h-full bg-white opacity-100 visible flex flex-wrap justify-center content-center`}>
            <div className='rounded-full text-zaxe fill-current shadow-[0_0px_10px_#009bde7c] '>
              <svg
                className={`animate-spin h-5 w-5`}
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'>
                <circle
                  className='opacity-50'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'></circle>
                <path
                  className='opacity-100'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
              </svg>
            </div>
            <span className='ml-3 text-sm text-zinc-500'>
              {t('misc.loading')}
            </span>
          </div>
          <div className='grid grid-cols-1 gap-5 xl:col-span-3 lg:col-span-3 col-span-full place-content-start place-items-start'>
            <h1 className='px-3 text-3xl font-extrabold text-zinc-700'>
              {t('pages.open-roles.select-team')}
            </h1>
            <div className='relative flex justify-start w-full grid-cols-1 overflow-x-scroll text-sm xl:grid gap-y-2 xl:text-base lg:text-base lg:grid place-content-start place-items-start gap-x-10 xl:overflow-auto lg:overflow-auto'>
              <button
                className={`${
                  selectedCategory === null && '!bg-zaxe/20'
                } active:scale-90 w-full gap-x-3 py-1 px-3 bg-white hover:bg-zaxe/20 transition-all duration-150 rounded-lg max-w-[10rem] text-zaxe flex justify-between`}
                onClick={() => setCategory(null)}>
                <span>{t('misc.all')}</span>
                <span>({careersData && careersData.length})</span>
              </button>
              {jobTeams &&
                jobTeams.map((jobTeam) => (
                  <button
                    key={`${jobTeam.slug}-${jobTeam.name}-selector`}
                    className={`${
                      selectedCategory &&
                      selectedCategory.slug === jobTeam.slug &&
                      '!bg-zaxe/20'
                    } w-full gap-x-3 active:scale-90 py-1 px-3 bg-white hover:bg-zaxe/20 transition-all duration-150 rounded-lg max-w-[10rem] text-zaxe flex justify-between`}
                    onClick={() => setCategory(jobTeam)}>
                    <span>
                      {jobTeam.name.length > 11
                        ? `${jobTeam.name.slice(0, 11)}..`
                        : jobTeam.name}
                    </span>
                    <span>({jobTeam.count})</span>
                  </button>
                ))}
            </div>
          </div>
          <div className='xl:col-span-7 max-h-[25rem] xl:min-h-[25rem] lg:min-h-[25rem] min-h-[10rem] jobs-table transition-all duration-150 overflow-y-scroll lg:col-span-7 col-span-full p-0 m-0 flex flex-wrap justify-start content-start'>
            <div className='relative table w-full p-0 m-0 text-xs transition-all duration-150 bg-white xl:text-base lg:text-base md:text-sm border-seperate'>
              <div className='w-full overflow-visible sticky top-[0px] left-0  z-10 bg-white table-row text-left text-zinc-700'>
                <div className='table-cell py-2'>
                  {t('pages.open-roles.table-header.job-title')}
                </div>
                <div className='table-cell py-2'>
                  {t('pages.open-roles.table-header.team')}
                </div>
                <div className='table-cell py-2'>
                  {t('pages.open-roles.table-header.location')}
                </div>
                <div className='table-cell py-2'>
                  {t('pages.open-roles.table-header.type')}
                </div>
                <div className='absolute w-full h-[1px] bg-zinc-400 left-0 z-5 xl:top-[40px] lg:top-[40px] top-[31px]'></div>
              </div>
              {careersData &&
                careersData.length > 0 &&
                careersData.map((role) =>
                  selectedCategory ? (
                    role.team.slug === selectedCategory.slug && (
                      <Link
                        to={`${
                          i18next.language === 'tr'
                            ? `/tr/job/${role.id}/apply`
                            : `/job/${role.id}/apply`
                        }`}
                        key={`${role.id}-job-link`}
                        className='table-row anim-fade-in hover:bg-zinc-100 text-zinc-500 hover:text-zinc-600'>
                        <div colSpan={2} className='table-cell px-1 py-2'>
                          {role.title}
                        </div>
                        <div className='table-cell px-1 py-2'>
                          {role.team.name}
                        </div>
                        <div className='table-cell px-1 py-2'>
                          {role.location.name}
                        </div>
                        <div className='table-cell px-1 py-2'>
                          {role.type.name}
                        </div>
                      </Link>
                    )
                  ) : (
                    <Link
                      to={`${
                        i18next.language === 'tr'
                          ? `/tr/job/${role.id}/apply`
                          : `/job/${role.id}/apply`
                      }`}
                      key={`${role.id}-job-link`}
                      className='table-row anim-fade-in hover:bg-zinc-100 text-zinc-500 hover:text-zinc-600'>
                      <div colSpan={2} className='table-cell px-1 py-2'>
                        {role.title}
                      </div>
                      <div className='table-cell px-1 py-2'>
                        {role.team.name}
                      </div>
                      <div className='table-cell px-1 py-2'>
                        {role.location.name}
                      </div>
                      <div className='table-cell px-1 py-2'>
                        {role.type.name}
                      </div>
                    </Link>
                  )
                )}
              {careersData && careersData.length <= 0 && (
                <div className='absolute flex flex-wrap content-center justify-center w-full h-full p-0 m-0 pt-14'>
                  <span className='text-sm font-semibold text-zinc-500'>
                    {t('misc.no-jobs')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
