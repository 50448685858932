import React from "react";
import Resources from "../../Resources";

export default function JobFooter() {
  return (
    <div className="w-full font-mark bg-zaxe xl:px-10 lg:px-10 px-5 py-10 flex flex-wrap justify-center content-start">
      <div className="max-w-7xl xl:px-10 lg:px-10 px-0 w-full grid grid-cols-1 place-items-start place-content-start ">
        <div className="w-full max-w-7xl bg-zaxe grid grid-cols-1 place-items-start place-content-start gap-1"></div>
        <div className="w-full max-w-[41rem] gap-3 bg-zaxe grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 place-items-between place-content-start">
          <div className="grid w-full max-w-[11rem] col-span-1 grid-cols-1 place-content-start place-items-start gap-2">
            <a href="/">
              <img
                width="100%"
                className="w-[6rem] object-contain"
                alt="Zaxe Logo"
                src={Resources.ZaxeLogoJustWhite}
              />
            </a>
            <h1 className="text-white font-extralight text-xs">
              Careers at Zaxe
            </h1>
          </div>
          <div className="col-span-1 w-full max-w-[11rem] gap-2 grid grid-cols-1 place-items-start place-content-start">
            <h1 className="text-white font-semibold text-xs">Our Office</h1>
            <p className="text-white font-extralight text-xs">
              Address: Yeşilce Mh. Seçilmiş Sk. No: 2 Levent/İstanbul
            </p>
          </div>
          <div className="col-span-1 w-full max-w-[11rem] gap-2 grid grid-cols-1 place-items-start place-content-start">
            <h1 className="text-white font-semibold text-xs">Business Hours</h1>
            <div>
              <p className="text-white font-extralight text-xs">
                Monday - Friday - 9am to 6pm
              </p>
              <p className="text-white font-extralight text-xs">
                Sunday - Saturday - Closed
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
