import { t } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Resources from '../Resources';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <Helmet>
          <title>{t('meta.names.404')}</title>
          <meta name='title' content={t('meta.names.404')} />
          <meta property='og:type' content='website' />
          <meta property='og:title' content={t('meta.names.404')} />
          <meta property='twitter:site' content='@Zaxe3D' />
          <meta property='twitter:site:id' content='@Zaxe3D' />
          <meta property='twitter:creator' content='@Zaxe3D' />
          <meta property='twitter:creator:id' content='@Zaxe3D' />
          <meta property='twitter:title' content={t('meta.names.404')} />
          <link
            rel='alternate'
            hrefLang='tr'
            href='https://careers.zaxe.com/tr/'
          />
          <link
            rel='alternate'
            hrefLang='en'
            href='https://careers.zaxe.com/'
          />
        </Helmet>
      </Helmet>
      <div
        style={{ backgroundImage: `url('${Resources.Banner}')` }}
        className='relative content-center min-h-screen bg-center bg-cover font-mark'>
        <div className='absolute top-0 left-0 flex justify-center w-full h-full bg-black/50'>
          <div className='flex flex-wrap content-center justify-center p-3'>
            <h1 className='w-full text-4xl font-bold text-center text-zinc-100'>
              {t('pages.not-found.header')}
            </h1>
            <p className='w-full py-3 text-lg font-semibold text-center text-zinc-300'>
              {t('pages.not-found.desc')}
            </p>
            <Link
              to={`/`}
              className='text-lg font-semibold text-center underline text-zaxe'>
              {t('pages.not-found.link')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
