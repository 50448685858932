import i18next, { t } from 'i18next';
import React from 'react';
import Contents from '../../Content';

export default function HowWeHire() {
  return (
    <div
      id='how-we-hire'
      className='flex justify-center w-full p-0 m-0 bg-white font-mark'>
      <div className='flex flex-wrap content-start justify-center w-full max-w-5xl p-10 m-0 gap-y-10'>
        <div className='grid w-full grid-cols-1 justify-items-start gap-y-3'>
          <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
            {t('pages.homepage.sections.how-we-hire.header')}
          </h1>
          <p className='w-full text-sm font-normal xl:text-md lg:text-md text-zinc-500'>
            {t('pages.homepage.sections.how-we-hire.description')}
          </p>
        </div>
        <div className='grid w-full grid-cols-1 gap-5 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 justify-items-stretch gap-x-7'>
          {Contents.howWeHire.columns.map((column, i) => (
            <div
              key={`hwh-${i}`}
              className='grid w-full grid-cols-1 place-items-start place-content-start justify-items-start gap-y-2'>
              <h1 className='text-lg font-normal xl:text-xl lg:text-xl text-zinc-500'>
                {column.header[i18next.language]}
              </h1>
              <p className='w-full max-w-md text-xs font-normal xl:text-md lg:text-md text-zinc-500'>
                {column.description[i18next.language]}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
