import React, { useState } from 'react';
import Resources from '../../Resources';

import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs';
import { t } from 'i18next';

export default function HomeSwiper() {
  const [selectedImage, setSelectedImage] = useState(0);
  const Images = [
    Resources.mainSlider.MainSliderTrust,
    Resources.mainSlider.MainSliderPassion,
    Resources.mainSlider.MainSliderAgility,
  ];

  const IndustryImage = () => (
    <div className='overflow-hidden scale-125 bg-zinc-100'>
      <div
        data-aos='zoom-out'
        data-aos-duration='700'
        className='h-screen w-screen xl:max-w-lg lg:max-w-lg xl:max-h-[24rem] lg:max-h-[24rem] max-h-[20rem] '>
        <img
          loading='lazy'
          className={`bg-black w-full h-full relative object-cover`}
          src={Images[selectedImage]}
          alt='Zaxe Careers'
        />
      </div>
    </div>
  );

  const ContentArea = () => (
    <>
      <div
        data-aos='fade'
        data-aos-duration='700'
        className='flex justify-start w-full pt-7'>
        <h2 className='text-xl font-normal xl:text-xl lg:text-2xl text-zinc-500'>
          {t(
            `pages.homepage.sections.home-swiper.swipes.${selectedImage}.header`
          )}
        </h2>
      </div>
      <div
        data-aos='fade'
        data-aos-duration='1000'
        className='flex justify-start w-full pt-1 pb-10'>
        <p className='xl:text-md xl:min-h-min lg:min-h-min min-h-[15rem] lg:text-md w-full max-w-sm text-sm text-zinc-500 font-normal'>
          {t(
            `pages.homepage.sections.home-swiper.swipes.${selectedImage}.description`
          )}
        </p>
      </div>
    </>
  );

  return (
    <div className='flex content-center justify-center w-full py-32 bg-white font-mark'>
      <div className='flex flex-wrap content-center justify-center w-full max-w-5xl'>
        <div className='grid content-center w-full max-w-md grid-cols-1 p-0 xl:max-w-full lg:max-w-full xl:grid-cols-2 lg:grid-cols-2 '>
          <div className='relative flex flex-wrap content-start justify-center w-full p-10 bg-zinc-100'>
            <div className='relative flex flex-wrap content-start justify-start w-full'>
              <div className='flex justify-start w-full'>
                <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
                  {t('pages.homepage.sections.home-swiper.header')}
                </h1>
              </div>
              <ContentArea />
            </div>
            <div className='w-full py-3 max-w-[6rem] z-10 absolute -bottom-4 left-10 text-4xl xl:pb-0 lg:pb-0 pb-10 text-white flex justify-start'>
              <div className='absolute grid w-full grid-cols-3 gap-2 swipe-counter -top-5 place-content-start place-items-center'>
                {Images.map((image, index) => (
                  <button
                    key={`swipe-counter-${index}`}
                    onClick={() => setSelectedImage(index)}
                    className={`transition-all ${
                      index === selectedImage && '!opacity-100 !scale-100'
                    } scale-75 opacity-50 duration-200 w-3 h-3 bg-zaxe rounded-full`}
                  />
                ))}
              </div>
              <button
                className='p-0 pl-5 transition-all duration-200 bg-zaxe brightness-90 active:brightness-50 disabled:brightness-75'
                onClick={() => {
                  setSelectedImage(selectedImage - 1);
                }}
                disabled={selectedImage === 0 ? true : false}>
                <BsArrowLeftShort className='p-0' />
              </button>
              <button
                className='p-0 transition-all duration-200 bg-zaxe active:brightness-50 disabled:brightness-75'
                onClick={() => setSelectedImage(selectedImage + 1)}
                disabled={selectedImage === Images.length - 1 ? true : false}>
                <BsArrowRightShort className='p-0' />
              </button>
            </div>
          </div>

          <IndustryImage />
        </div>
      </div>
    </div>
  );
}
