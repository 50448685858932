import i18next, { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Contents from '../Content';
import Resources from '../Resources';

import { HiOutlineChevronDown } from 'react-icons/hi';
import { HashLink } from 'react-router-hash-link';

export default function Header() {
  const [mobileNav, setMobileNav] = useState(false);
  const pathname = useLocation();

  useEffect(() => {
    setMobileNav(false);
    !window.location.href.includes('#') && window.scrollTo({ top: 0 });
  }, [pathname]);

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [mobileNav]);

  return (
    <header
      className='z-[40] font-mark absolute top-0 left-0 w-full bg-transparent 
    py-3 px-3 flex flex-wrap justify-center content-center m-0'>
      <div className='w-full max-w-7xl flex flex-wrap justify-between content-center p-0'>
        <div className='left-logo-side'>
          <a
            href='/'
            className='flex hover:bg-opacity-10 bg-opacity-0 bg-white rounded-xl p-3 transition-all duration-200 hover:shadow-white content-center justify-center gap-x-3'>
            <img
              loading='lazy'
              src={Resources.ZaxeLogoWhite}
              className='w-screen max-w-[5rem] self-center'
              alt='Zaxe Logo'
            />
            <span className='h-7 w-px bg-white bg-opacity-60 self-center' />
            <h1 className='text-xl text-white mt-1 font-medium self-center'>
              {t('header.careers')}
            </h1>
          </a>
        </div>
        <nav className='right-navigation text-white xl:flex lg:flex hidden flex-wrap justify-end content-center gap-x-7 p-3'>
          {Contents.header.navigation.navArray.map((nav, x) => (
            <HashLink
              key={`desktopNav${x}`}
              to={nav.links[i18next.language]}
              className='relative self-center flex text-sm flex-wrap justify-center group'>
              <span className='w-full'>{nav.name[i18next.language]}</span>
              <div className='h-px w-0 transition-all duration-200 bg-white group-hover:w-full' />
            </HashLink>
          ))}

          <a
            href={Contents.header.navigation.backToZaxe.links[i18next.language]}
            className='relative self-center flex text-sm flex-wrap justify-center group'>
            <span className='w-full'>
              {t('header.navigation.back-to-zaxe')}
            </span>
            <div className='h-px w-0 transition-all duration-200 bg-white group-hover:w-full' />
          </a>
          <div className='relative group text-center self-center p-1 overflow-visible text-xs rounded-sm bg-black bg-opacity-20 text-white text flex justify-center content-center'>
            <span className='self-center text-center p-1'>
              {i18next.language.toUpperCase()}
            </span>
            <HiOutlineChevronDown className='self-center p-1 text-lg group-hover:rotate-180 transition-all duration-200' />
            <div className='absolute w-full rounded-sm flex flex-wrap justify-center content-center bg-transparent pt-2 top-full left-0 group-hover:visible group-hover:opacity-100 opacity-0 invisible transition-all duration-200'>
              <div className='p-1 scale-75 group-hover:scale-100 w-full rounded-sm flex flex-wrap justify-center content-center bg-black bg-opacity-20 transition-all duration-200'>
                <a
                  className='hover:underline'
                  href={
                    window.location.pathname.slice(0, 3) === '/tr'
                      ? `${
                          window.location.origin
                        }${window.location.pathname.slice(
                          3,
                          window.location.pathname.length
                        )}${window.location.search}`
                      : `/tr${window.location.pathname}${window.location.search}`
                  }>
                  {i18next.language === 'tr' ? 'EN' : 'TR'}
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className='xl:hidden lg:hidden flex flex-wrap p-3 mt-0.5 overflow-hidden self-center content-center'>
          <label
            className={`z-[40] mobil-menu-button self-center fill-current ${
              mobileNav ? 'text-black' : 'text-white'
            }  p-0 m-0`}
            htmlFor='mobilHamburger'>
            <input
              onChange={(e) => setMobileNav(e.target.checked)}
              className='hidden'
              id='mobilHamburger'
              type='checkbox'
              checked={mobileNav}
            />
            <span className={mobileNav ? '!bg-black' : '!bg-white'}></span>
            <span className={mobileNav ? '!bg-black' : '!bg-white'}></span>
            <span className={mobileNav ? '!bg-black' : '!bg-white'}></span>
          </label>
        </div>
        <div
          className={`fixed xl:hidden lg:hidden flex flex-wrap justify-start content-start p-3 m-0 transition-all duration-500 h-screen w-screen bg-white z-[35] top-0 ${
            mobileNav ? 'left-0' : 'left-full'
          }`}>
          <a
            href='/'
            className='flex hover:bg-opacity-10 bg-opacity-0 bg-black rounded-xl p-3 transition-all duration-200 hover:shadow-white content-center justify-center gap-x-3'>
            <img
              loading='lazy'
              src={Resources.ZaxeLogoBlack}
              className='w-screen max-w-[5rem] self-center'
              alt='Zaxe Logo'
            />
            <span className='h-7 w-px bg-black bg-opacity-60 self-center' />
            <h1 className='text-xl text-black mt-1 font-medium self-center'>
              {t('header.careers')}
            </h1>
          </a>
          <div className='w-full text-zinc-700 font-light text-sm p-3 flex flex-wrap justify-start content-start gap-y-5'>
            {Contents.header.navigation.navArray.map((nav, x) => (
              <HashLink
                key={`mobileNav${x}`}
                to={nav.links[i18next.language]}
                className='w-full  border-b border-b-zinc-300'>
                {nav.name[i18next.language]}
              </HashLink>
            ))}

            <a
              href={
                Contents.header.navigation.backToZaxe.links[i18next.language]
              }
              className='w-full border-b border-b-zinc-300'>
              {t('header.navigation.back-to-zaxe')}
            </a>
            <a
              href={
                window.location.pathname.slice(0, 3) === '/tr'
                  ? `${window.location.origin}${window.location.pathname.slice(
                      3,
                      window.location.pathname.length
                    )}${window.location.search}`
                  : `/tr${window.location.pathname}${window.location.search}`
              }
              className='w-full border-b border-b-zinc-300'>
              {i18next.language === 'tr' ? 'EN' : 'TR'}{' '}
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
