import React from 'react';
import { render } from 'react-dom';

import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

/* Styles */
import './index.css';
import './Styles/Fonts/Fonts.css';
import './Styles/Header.css';
import './Styles/Animations.css';

import App from './App';
const rootElement = document.getElementById('zaxe-careers');
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    debug: false,
    whitelist: ['en', 'tr'],
    supportedLngs: ['en', 'tr'],
    fallbackLng: 'en',
    detection: {
      order: ['path', 'htmlTag'],
    },
    backend: {
      loadPath: `/assets/locales/translations/{{lng}}/translation.json`,
    },
    react: {
      useSuspense: false,
    },
  })
  .then(() => {
    render(<App />, rootElement);
  });
