import i18next, { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import Contents from '../../Content';
import Resources from '../../Resources';

export default function FindYourTeam() {
  return (
    <div className='flex content-center justify-center w-full p-0 m-0 bg-white font-mark'>
      <div className='w-full flex justify-center content-center p-0 m-0 bg-white max-w-[1920px]'>
        <div className='flex flex-wrap content-start justify-center w-full '>
          <div className='grid w-full max-w-5xl grid-cols-1 p-10 justify-items-start place-items-center gap-y-3'>
            <h1 className='text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe'>
              {t('pages.homepage.sections.find-your-team.header')}
            </h1>
            <p className='w-full max-w-sm text-sm font-normal xl:text-md lg:text-md text-zinc-500'>
              {t('pages.homepage.sections.find-your-team.description')}
            </p>
          </div>
          <div className='grid w-full grid-cols-1 justify-items-center xl:grid-cols-2 lg:grid-cols-2'>
            <div
              className='bg-cover min-h-[22rem] bg-center bg-no-repeat w-full p-0 m-0'
              style={{
                backgroundImage: `url('${Resources.WWYK_Banner}')`,
              }}>
              <div className='flex flex-wrap content-end justify-start w-full h-full transition-all duration-200 bg-black hover:bg-opacity-80 bg-opacity-60 xl:justify-end lg:justify-end'>
                <div className='w-full max-w-[32rem] p-10 xl:gap-y-5 lg:gap-y-5 gap-y-2.5 grid grid-cols-1 justify-items-start'>
                  <h1 className='text-2xl font-extrabold text-white xl:text-3xl lg:text-3xl'>
                    {
                      Contents.findYourTeam['element-1'].header[
                        i18next.language
                      ]
                    }
                  </h1>
                  <p className='w-full max-w-xs text-sm font-normal text-white xl:text-md lg:text-md'>
                    {
                      Contents.findYourTeam['element-1'].description[
                        i18next.language
                      ]
                    }
                  </p>
                  <Link
                    to={
                      Contents.findYourTeam['element-1'].links[i18next.language]
                    }
                    className='max-w-sm p-1 px-5 text-sm text-center text-white transition-all duration-200 bg-zaxe hover:bg-white hover:text-zaxe xl:text-md lg:text-md'>
                    {t('buttons.view-open-roles')}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className=' bg-cover min-h-[22rem] bg-center bg-no-repeat w-full p-0 m-0'
              style={{
                backgroundImage: `url('${Resources.FYT_SalesServices}')`,
              }}>
              <div className='flex flex-wrap content-end justify-start w-full h-full transition-all duration-200 bg-black hover:bg-opacity-80 bg-opacity-60'>
                <div className='w-full max-w-[32rem] p-10 xl:gap-y-5 lg:gap-y-5 gap-y-2.5 grid grid-cols-1 justify-items-start'>
                  <h1 className='text-2xl font-extrabold text-white xl:text-3xl lg:text-3xl'>
                    {
                      Contents.findYourTeam['element-2'].header[
                        i18next.language
                      ]
                    }
                  </h1>
                  <p className='w-full max-w-xs text-sm font-normal text-white xl:text-md lg:text-md'>
                    {
                      Contents.findYourTeam['element-2'].description[
                        i18next.language
                      ]
                    }
                  </p>
                  <Link
                    to={
                      Contents.findYourTeam['element-2'].links[i18next.language]
                    }
                    className='max-w-sm p-1 px-5 text-sm text-center text-white transition-all duration-200 bg-zaxe hover:bg-white hover:text-zaxe xl:text-md lg:text-md'>
                    {t('buttons.view-open-roles')}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className='bg-cover min-h-[22rem] bg-center bg-no-repeat w-full p-0 m-0'
              style={{
                backgroundImage: `url('${Resources.FYT_Manufacturing}')`,
              }}>
              <div className='flex flex-wrap content-end justify-start w-full h-full transition-all duration-200 bg-black hover:bg-opacity-80 bg-opacity-60 xl:justify-end lg:justify-end'>
                <div className='w-full max-w-[32rem] p-10 xl:gap-y-5 lg:gap-y-5 gap-y-2.5 grid grid-cols-1 justify-items-start'>
                  <h1 className='text-2xl font-extrabold text-white xl:text-3xl lg:text-3xl'>
                    {
                      Contents.findYourTeam['element-6'].header[
                        i18next.language
                      ]
                    }
                  </h1>
                  <p className='w-full max-w-xs text-sm font-normal text-white xl:text-md lg:text-md'>
                    {
                      Contents.findYourTeam['element-6'].description[
                        i18next.language
                      ]
                    }
                  </p>
                  <Link
                    to={
                      Contents.findYourTeam['element-6'].links[i18next.language]
                    }
                    className='max-w-sm p-1 px-5 text-sm text-center text-white transition-all duration-200 bg-zaxe hover:bg-white hover:text-zaxe xl:text-md lg:text-md'>
                    {t('buttons.view-open-roles')}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className=' bg-cover min-h-[22rem] bg-center bg-no-repeat w-full p-0 m-0'
              style={{ backgroundImage: `url('${Resources.FYT_Marketing}')` }}>
              <div className='flex flex-wrap content-end justify-start w-full h-full transition-all duration-200 bg-black hover:bg-opacity-80 bg-opacity-60'>
                <div className='w-full max-w-[32rem] p-10 xl:gap-y-5 lg:gap-y-5 gap-y-2.5 grid grid-cols-1 justify-items-start'>
                  <h1 className='text-2xl font-extrabold text-white xl:text-3xl lg:text-3xl'>
                    {
                      Contents.findYourTeam['element-3'].header[
                        i18next.language
                      ]
                    }
                  </h1>
                  <p className='w-full max-w-xs text-sm font-normal text-white xl:text-md lg:text-md'>
                    {
                      Contents.findYourTeam['element-3'].description[
                        i18next.language
                      ]
                    }
                  </p>
                  <Link
                    to={
                      Contents.findYourTeam['element-3'].links[i18next.language]
                    }
                    className='max-w-sm p-1 px-5 text-sm text-center text-white transition-all duration-200 bg-zaxe hover:bg-white hover:text-zaxe xl:text-md lg:text-md'>
                    {t('buttons.view-open-roles')}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className=' bg-cover min-h-[22rem] bg-center bg-no-repeat w-full p-0 m-0'
              style={{
                backgroundImage: `url('${Resources.FYT_Internships}')`,
              }}>
              <div className='flex flex-wrap content-end justify-start w-full h-full transition-all duration-200 bg-black hover:bg-opacity-80 bg-opacity-60 xl:justify-end lg:justify-end'>
                <div className='w-full max-w-[32rem] p-10 xl:gap-y-5 lg:gap-y-5 gap-y-2.5 grid grid-cols-1 justify-items-start'>
                  <h1 className='text-2xl font-extrabold text-white xl:text-3xl lg:text-3xl'>
                    {
                      Contents.findYourTeam['element-4'].header[
                        i18next.language
                      ]
                    }
                  </h1>
                  <p className='w-full max-w-xs text-sm font-normal text-white xl:text-md lg:text-md'>
                    {
                      Contents.findYourTeam['element-4'].description[
                        i18next.language
                      ]
                    }
                  </p>
                  <Link
                    to={
                      Contents.findYourTeam['element-4'].links[i18next.language]
                    }
                    className='max-w-sm p-1 px-5 text-sm text-center text-white transition-all duration-200 bg-zaxe hover:bg-white hover:text-zaxe xl:text-md lg:text-md'>
                    {t('buttons.view-open-roles')}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className=' bg-cover min-h-[22rem] bg-center bg-no-repeat w-full p-0 m-0'
              style={{
                backgroundImage: `url('${Resources.FYT_InternalOperations}')`,
              }}>
              <div className='flex flex-wrap content-end justify-start w-full h-full transition-all duration-200 bg-black hover:bg-opacity-80 bg-opacity-60'>
                <div className='w-full max-w-[32rem] p-10 xl:gap-y-5 lg:gap-y-5 gap-y-2.5 grid grid-cols-1 justify-items-start'>
                  <h1 className='text-2xl font-extrabold text-white xl:text-3xl lg:text-3xl'>
                    {
                      Contents.findYourTeam['element-5'].header[
                        i18next.language
                      ]
                    }
                  </h1>
                  <p className='w-full max-w-xs text-sm font-normal text-white xl:text-md lg:text-md'>
                    {
                      Contents.findYourTeam['element-5'].description[
                        i18next.language
                      ]
                    }
                  </p>
                  <Link
                    to={
                      Contents.findYourTeam['element-5'].links[i18next.language]
                    }
                    className='max-w-sm p-1 px-5 text-sm text-center text-white transition-all duration-200 bg-zaxe hover:bg-white hover:text-zaxe xl:text-md lg:text-md'>
                    {t('buttons.view-open-roles')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
