import Resources from './Resources';

const Contents = {
  header: {
    navigation: {
      navArray: [
        {
          name: {
            tr: 'Biz Kimiz',
            en: 'Who We Are',
          },
          slug: 'who-we-are',
          links: {
            en: '/#who-we-are',
            tr: '/tr#who-we-are',
          },
        },
        {
          name: {
            tr: 'Açık Pozisyonlar',
            en: 'Open Roles',
          },
          slug: 'open-roles',
          links: {
            en: '/open-roles',
            tr: '/tr/open-roles',
          },
        },
        {
          name: {
            tr: 'Nasıl İşe Alıyoruz',
            en: 'How We Hire',
          },
          slug: 'how-we-hire',
          links: {
            en: '/#how-we-hire',
            tr: '/tr#how-we-hire',
          },
        },
      ],
      backToZaxe: {
        name: {
          tr: "zaxe.com'a dön",
          en: 'back to zaxe.com',
        },
        slug: 'back-to-zaxe',
        links: {
          en: 'https://zaxe.com/',
          tr: 'https://zaxe.com/tr/',
        },
      },
    },
  },
  findYourTeam: {
    'element-1': {
      header: {
        tr: 'Mühendislik & Ürün',
        en: 'Engineering & Product',
      },
      description: {
        tr: 'Her gün büyüyen ARGE takımımızın bir parçası olarak endüstrideki gelişmelere liderlik etmemizi sağlayın.',
        en: 'Become a member of our growing Research & Development team and help us lead the way in innovation.',
      },
      links: {
        tr: '/tr/open-roles?category=engineering-product',
        en: '/open-roles?category=engineering-product',
      },
    },
    'element-2': {
      header: {
        tr: 'Satış & Müşteri Hizmetleri',
        en: 'Sales & Services',
      },
      description: {
        tr: 'Organizasyonumuzun yüzü olmaları ve ürünlerimizi satmaları için yüksek sosyal becerilere sahip takım arkadaşları arıyoruz.',
        en: 'We are looking for team members with people skills to become the face of our organization and finalize sales.',
      },
      links: {
        tr: '/tr/open-roles?category=sales-services',
        en: '/open-roles?category=service-services',
      },
    },
    'element-3': {
      header: {
        tr: 'Pazarlama',
        en: 'Marketing',
      },
      description: {
        tr: 'Pazarlama ekibimize katılın ve markamızın değerlerini hedef kitlemize etkili bir şekilde iletmemizi sağlayın.',
        en: 'Work in our marketing department and help us communicate the value of our brand better to our target audience.',
      },
      links: {
        tr: '/tr/open-roles?category=marketing',
        en: '/open-roles?category=marketing',
      },
    },
    'element-4': {
      header: {
        tr: 'Stajyerlik',
        en: 'Internships',
      },
      description: {
        tr: 'Geleceğiniz için önemli bir adım atın ve Zaxe’de stajyerliğe başvurarak yeni bir kariyer yolu keşfedin.',
        en: 'Take an important step for your career by applying to our internship program and discover a new career opportunity.',
      },
      links: {
        tr: '/tr/open-roles?category=internship',
        en: '/open-roles?category=internship',
      },
    },
    'element-5': {
      header: {
        tr: 'İç Operasyonlar',
        en: 'Operations',
      },
      description: {
        tr: 'Zaxe’nin daha işlek bir organizasyon olmasına yardımcı olmak için İK, bilgi işlem ve muhasebe takımlarımızın bir parçası olun.',
        en: 'Help Zaxe 3D become a more efficient organization in its internal operations and work with our HR, IT, and accounting teams.',
      },
      links: {
        tr: '/tr/open-roles?category=internal',
        en: '/open-roles?category=internal',
      },
    },
    'element-6': {
      header: {
        tr: 'Üretim',
        en: 'Manufacturing',
      },
      description: {
        tr: 'Üretim departmanında bir işe başvurun ve Zaxe’yi bugün olduğu yere taşıyan 3 boyutlu yazıcıları üretmemize yardımcı olun.',
        en: 'Apply for a manufacturing position and become a member of the team that makes the very printers that carried Zaxe 3D to where it is today.',
      },
      links: {
        tr: '/tr/open-roles?category=manufacturing',
        en: '/open-roles?category=manufacturing',
      },
    },
  },
  howWeHire: {
    columns: [
      {
        header: { tr: 'Bizimle Tanış', en: 'Meet Us' },
        description: {
          tr: 'Ofisimizi ziyaret edin ve bizimle yüzyüze görüşün. Binamızda ufak bir turdan sonra, sizi takım üyelerimizle tanıştıralım.',
          en: 'Visit our offices and meet us in person. We will give you a tour of our base of operations and introduce you to the individual members of the team. ',
        },
      },
      {
        header: { tr: 'Kendinizi Tanıtın', en: 'Talk to Us' },
        description: {
          tr: 'İlk konuşmalarımız, ilgileriniz, yetenekleriniz ve tecrübeniz üzerine olacak. Düşünme şeklinizi ve gelecek hakkındaki planlarınızı anlamaya çalışacağız.',
          en: 'Our initial conversations will be focused on your interests, experience and strengths. We will try to understand your thought process and plans for the future.',
        },
      },
      {
        header: { tr: 'Kendinizi Test Edin', en: 'Take a Challenge' },
        description: {
          tr: 'Çalışma metodlarınızı daha iyi anlamak için kısa bir deneme sürecinde bizimle çalışın. Bu süreçte sizi markamız ve 3 boyutlu yazıcı sektörü hakkında bilgilendireceğiz.',
          en: 'Show us how you work in a testing period to give us a better idea of your methods. At this stage we will help you learn more about the brand and 3D printing industry.',
        },
      },
      {
        header: { tr: 'Takımımıza Katılın', en: 'Join Us' },
        description: {
          tr: 'Zaxe ailesinin bir parçası olun ve bir endüstriyi değiştirmeyi planlayan bir ekibe katılın. Zaxe’de sizi çok güzel deneyimler bekliyor.',
          en: 'Become a member of the Zaxe team and work with us to reinvent the 3D printing industry. Great experiences are waiting for you here.',
        },
      },
    ],
  },
  whatWeOffer: {
    columns: [
      {
        icon: Resources.icons.ImpactIcon,
        description: {
          tr: 'Sorumluluk sahibi olmak',
          en: 'A chance to make an impact',
        },
      },
      {
        icon: Resources.icons.ChallengeIcon,
        description: {
          tr: 'Heyecan verici zorluklar',
          en: 'Exciting challenges',
        },
      },
      {
        icon: Resources.icons.WorkspaceIcon,
        description: {
          tr: 'Yaratıcı çalışma ortamı',
          en: 'Creative workspace',
        },
      },
      {
        icon: Resources.icons.LearnIcon,
        description: {
          tr: 'Yeni yetenekler öğrenme',
          en: 'Learn from inspiring talents',
        },
      },
    ],
  },
  peoples: {
    allPeoples: [
      {
        name: 'Baki Gezgen',
        avatar: null,
        desc: { tr: "Zaxe'de Kurucu", en: 'Founder at Zaxe' },
        link: '',
      },
      {
        name: 'Osman Danışment',
        avatar: null,
        desc: { tr: "Zaxe'de Sanat Yönetmeni", en: 'Art Director at Zaxe' },
        link: '',
      },
      {
        name: 'Ege İnsel',
        avatar: null,
        desc: { tr: "Zaxe'de Satış Menajeri", en: 'Sales Manager at Zaxe' },
        link: '',
      },
      {
        name: 'Baki Gezgen',
        avatar: null,
        desc: { tr: "Zaxe'de Kurucu", en: 'Founder at Zaxe' },
        link: '',
      },
      {
        name: 'Osman Danışmanent',
        avatar: null,
        desc: { tr: "Zaxe'de Sanat Yönetmeni", en: 'Art Director at Zaxe' },
        link: '',
      },
      {
        name: 'Ege İnsel',
        avatar: null,
        desc: { tr: "Zaxe'de Satış Menajeri", en: 'Sales Manager at Zaxe' },
        link: '',
      },
    ],
  },
};

export default Contents;
