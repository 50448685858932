/* eslint-disable no-unused-vars */
import React, { useEffect, useState, createContext } from 'react';

const CareersContext = createContext();

export const CareersContextProvider = ({ children }) => {
  const [careersData, setCareersData] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setCareersData([
        {
          id: '6172832',
          title: 'C++ Developer',
          team: {
            slug: 'engineering-product',
            name: 'Engineering & Product',
          },
          location: {
            id: '0001',
            slug: 'istanbul',
            name: 'Istanbul',
          },
          inputs: [
            {
              type: 'file',
              slug: 'resume',
              label: 'Resume / CV',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'github',
              label: 'GitHub',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'behance',
              label: 'Behance',
              isActive: false,
            },
            {
              type: 'url',
              slug: 'portfolio',
              label: 'Portfolio',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'linkedin',
              label: 'Linkedin',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'cover-letter',
              label: 'Cover Letter',
              isActive: true,
            },
          ],
          type: {
            slug: 'full-time',
            name: 'Full Time',
          },
          descriptions: {
            aboutJob:
              'We are looking for a Senior C++ Developer who will be responsible for the development of our 3D printing slicer, xDesktop. You will help us develop one of the most efficient and user-experience oriented desktop slicers on the market and make 3D printing more accessible for our customers. This role comes with a lot of autonomy and responsibility as you will be leading the project.',
            'section-1': {
              title: 'What we except from you',
              values: [
                {
                  value: '5+ years of experience in software development.',
                },
                {
                  value: 'High proficiency in C++.',
                },
                {
                  value:
                    'Experience in user interface programming (wxWidgets or Qt).',
                },
                {
                  value: 'Extensive knowledge of OOP, Design Patterns.',
                },
                {
                  value:
                    'Software development experience for Windows, OSX, Linux, and cross-platform programming.',
                },
                {
                  value:
                    'Being a pragmatic engineer who avoids overengineering of solutions and understands how to maintain a high-quality of code in a delivery-focused environment.',
                },
                {
                  value: 'Develop and run unit tests.',
                },
                {
                  value: 'Experience with Git, Github.',
                },
              ],
            },
            'section-2': {
              title: 'You will be dealing with',
              values: [
                {
                  value:
                    'Development of new advanced features for the Zaxe 3D printer software, xDesktop.',
                },
                {
                  value:
                    'Working in an agile work environment with a team of passionate, talented, and creative people.',
                },
                {
                  value: 'Development of targeted features.',
                },
                {
                  value: 'Meeting security and data protection standards.',
                },
                {
                  value:
                    'Fulfill industry-standard software development lifecycle tasks.',
                },
                {
                  value: 'Collaborating with other team members.',
                },
              ],
            },
            'section-3': {
              title: 'We offer',
              values: [
                {
                  value: 'Flexible vacation.',
                },
                {
                  value: 'Premium healthcare.',
                },
                {
                  value: 'Paid parental leave.',
                },
                {
                  value: 'Commuter benefits.',
                },
                {
                  value: 'Access to 3D printers for personal projects.',
                },
              ],
            },
          },
        },
        {
          id: '3214556',
          title: 'Mechanical Engineer',
          team: {
            slug: 'engineering-product',
            name: 'Engineering & Product',
          },
          location: {
            id: '0001',
            slug: 'istanbul',
            name: 'Istanbul',
          },
          inputs: [
            {
              type: 'file',
              slug: 'resume',
              label: 'Resume / CV',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'github',
              label: 'GitHub',
              isActive: false,
            },
            {
              type: 'url',
              slug: 'behance',
              label: 'Behance',
              isActive: false,
            },
            {
              type: 'url',
              slug: 'portfolio',
              label: 'Portfolio',
              isActive: false,
            },
            {
              type: 'url',
              slug: 'linkedin',
              label: 'Linkedin',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'cover-letter',
              label: 'Cover Letter',
              isActive: true,
            },
          ],
          type: {
            slug: 'full-time',
            name: 'Full Time',
          },
          descriptions: {
            aboutJob:
              'We are looking for a Mechanical Engineer for our R&D team to help us with the development of the mechanical parts for our 3D printers. You will be working with a team of passionate 3D printing enthusiasts who are working on developing new hardware and software to lead the way in terms of innovation in the industry. This role requires a creative mind, the ability to work collaboratively with a team, and amazing problem-solving capabilities.',
            'section-1': {
              title: 'What we except from you',
              values: [
                {
                  value: '5+ years of experience in mechanical design.',
                },
                {
                  value: 'High proficiency in SOLIDWORKS 3D.',
                },
                {
                  value: 'Experience (knowledge of) with sheet metal design.',
                },
                {
                  value:
                    'Experience with FEA tools such as Ansys, Solidworks Simulation.',
                },
                {
                  value: 'Experience with the full product development cycle.',
                },
                {
                  value: 'Crafting new designs that function with efficiency.',
                },
                {
                  value:
                    'Prototyping new mechanisms, testing materials, and researching 3D printing technologies.',
                },
                {
                  value:
                    'Create detailed designs in CAD and well-specified, accurate drawings.',
                },
                {
                  value:
                    'Collaborating with our Global Sourcing and Manufacturing Teams to bring designs from prototype to mass production.',
                },
              ],
            },
            'section-2': {
              title: 'You will be dealing with',
              values: [
                {
                  value:
                    'Managing the development process of the mechanical components of our Zaxe Z Series 3D Printer production line.',
                },
                {
                  value:
                    'Designing the mechanical components of the future devices.',
                },
                {
                  value:
                    'Planning and supervising the manufacturing process of the products.',
                },
                {
                  value:
                    'Analysis of the mechanical simulations of our designs on Ansys or similar tools.',
                },
              ],
            },
            'section-3': {
              title: 'We offer',
              values: [
                {
                  value: 'Flexible vacation.',
                },
                {
                  value: 'Premium healthcare.',
                },
                {
                  value: 'Paid parental leave.',
                },
                {
                  value: 'Commuter benefits.',
                },
                {
                  value: 'Access to 3D printers for personal projects.',
                },
              ],
            },
          },
        },
        {
          id: '4432570',
          title: 'Graphic Design Intern',
          team: {
            slug: 'internships',
            name: 'Internships',
          },
          location: {
            id: '0001',
            slug: 'istanbul',
            name: 'Istanbul',
          },
          inputs: [
            {
              type: 'file',
              slug: 'resume',
              label: 'Resume / CV',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'github',
              label: 'GitHub',
              isActive: false,
            },
            {
              type: 'url',
              slug: 'behance',
              label: 'Behance',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'portfolio',
              label: 'Portfolio',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'linkedin',
              label: 'Linkedin',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'cover-letter',
              label: 'Cover Letter',
              isActive: true,
            },
          ],
          type: {
            slug: 'intern',
            name: 'Intern',
          },
          descriptions: {
            aboutJob:
              "We are looking for a Graphic Design Intern that will be working with the Digital Marketing Team of our company. You will help us by providing content that will be used in the promotional footage of our company's products.\n\nIf you are interested in this position, make sure to send your CV and Portfolio to us.\nCaution: Applications that do not include a portfolio will not be taken into consideration.",
            'section-1': {
              title: 'What we except from you',
              values: [
                {
                  value: 'Experience with Adobe Photoshop & Illustrator.',
                },
                {
                  value: 'Has a decent grasp on Adobe After Effects.',
                },
                {
                  value: 'Being able to work in a team environment.',
                },
                {
                  value:
                    'The ability to follow directions and contribute to the creative process.',
                },
                {
                  value:
                    'Willingness to learn new things and develop new skills.',
                },
                {
                  value:
                    'Familiar with design criteria of different social media platforms (Instagram Posts&Stories, Twitter, YouTube Thumbnails etc.)',
                },
                {
                  value:
                    'Preferably has a degree in related fields or a 3rd or 4th-year student.',
                },
                {
                  value:
                    'Native or Bilingual Turkish and at least intermediate English speaker.',
                },
              ],
            },
            'section-2': {
              title: 'You will be dealing with',
              values: [
                {
                  value:
                    'Dealing with the graphic designs of various social posts.',
                },
                {
                  value: 'Providing visuals for our blog posts.',
                },
                {
                  value: 'Preparing templates for our content team.',
                },
                {
                  value:
                    'Helping with designing visuals for company presentations.',
                },
                {
                  value:
                    'Helping to create brochures and catalogs for upcoming products.',
                },
              ],
            },
            'section-3': {
              title: 'We offer',
              values: [
                {
                  value:
                    'Future opportunities in the company as a permanent part of the team.',
                },
                {
                  value: 'Access to 3D printers for personal projects.',
                },
                {
                  value:
                    'An agile work environment that encourages you to be a part of the process.',
                },
                {
                  value:
                    'A team of passionate, experienced people who are willing to share their expertise. ',
                },
              ],
            },
          },
        },
        {
          id: '4432585',
          title: 'Videographer Intern',
          team: {
            slug: 'internships',
            name: 'Internships',
          },
          location: {
            id: '0001',
            slug: 'istanbul',
            name: 'Istanbul',
          },
          inputs: [
            {
              type: 'file',
              slug: 'resume',
              label: 'Resume / CV',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'github',
              label: 'GitHub',
              isActive: false,
            },
            {
              type: 'url',
              slug: 'behance',
              label: 'Behance',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'portfolio',
              label: 'Portfolio',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'linkedin',
              label: 'Linkedin',
              isActive: true,
            },
            {
              type: 'url',
              slug: 'cover-letter',
              label: 'Cover Letter',
              isActive: true,
            },
          ],
          type: {
            slug: 'intern',
            name: 'Intern',
          },
          descriptions: {
            aboutJob:
              "We are looking for a Videographer Intern that will be working with the Digital Marketing Team of our company. You will help us with the production, shooting, and editing of video content that will be used in the promotional footage of our company's products.\n\nIf you are interested in this position, make sure to send your CV and Portfolio to us.\nCaution: Applications that do not include a portfolio will not be taken into consideration.",
            'section-1': {
              title: 'What we except from you',
              values: [
                {
                  value: 'Experience with mirrorless & DSLR cameras.',
                },
                {
                  value: 'Familiarity with light and sound equipment.',
                },
                {
                  value:
                    'Knowledge of lighting, shot composition, and framing principles. ',
                },
                {
                  value:
                    'Experience with Adobe Premier & Photoshop and motivation to learn more.',
                },
                {
                  value: 'Having a sense of aesthetics and an eye for detail.',
                },
                {
                  value:
                    'Willingness to work with a team of young, passionate, and agile individuals.',
                },
                {
                  value:
                    'Preferably has a degree in related fields or a 3rd or 4th-year student.',
                },
                {
                  value:
                    'Native or Bilingual Turkish and at least intermediate English speaker.',
                },
              ],
            },
            'section-2': {
              title: 'You will be dealing with',
              values: [
                {
                  value:
                    'Assisting in the production, shooting, and editing process of video content.',
                },
                {
                  value:
                    'Setting up and participating in the photoshoots that will be used as promotional content.',
                },
                {
                  value:
                    'Helping with the framing, lighting, and composition of these shootings.',
                },
                {
                  value:
                    'Assisting with the editing, time coding, and assortment of raw video footage.',
                },
                {
                  value: 'Helping us to keep the studio environment tidy.',
                },
              ],
            },
            'section-3': {
              title: 'We offer',
              values: [
                {
                  value:
                    'Future opportunities in the company as a permanent part of the team.',
                },
                {
                  value: 'Access to 3D printers for personal projects.',
                },
                {
                  value:
                    'An agile work environment that encourages you to be a part of the process.',
                },
                {
                  value:
                    'A team of passionate, experienced people who are willing to share their expertise.',
                },
              ],
            },
          },
        },
      ]);
    }, 500);
  }, []);
  const value = { careersData };

  return (
    <CareersContext.Provider value={value}>{children}</CareersContext.Provider>
  );
};

export default CareersContext;
