import React from 'react';
import 'aos/dist/aos.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import AOS from 'aos';
import Header from './Components/Header';

/* Components */
import Loader from './Components/Loader';
import Homepage from './Pages/Homepage';
import OpenRoles from './Pages/OpenRoles';
import { CareersContextProvider } from './Contexts/CareersContext';
import JobApply from './Pages/JobApply';
import NotFound from './Pages/NotFound';
import RecruitmenCandidatesDisclosure from './Pages/Law/RecruitmenCandidatesDisclosure';
import RecruitmenCandidatesConsent from './Pages/Law/RecruitmenCandidatesConsent';

AOS.init();
smoothscroll.polyfill();
window.__forceSmoothScrollPolyfill__ = true;
function App() {
  const lang = '/:lang(en|tr)?';
  return (
    <Router>
      <CareersContextProvider>
        <Header />
        <Loader />
        <Switch>
          <Route exact path={lang} component={Homepage} />
          <Route exact path={`${lang}/open-roles`} component={OpenRoles} />
          <Route exact path={`${lang}/job/:id/apply`} component={JobApply} />
          <Route
            exact
            path={`${lang}/statement-of-disclosure-for-recruitmen-candidates`}
            component={RecruitmenCandidatesDisclosure}
          />
          <Route
            exact
            path={`${lang}/recruitment-candidates-consent-text`}
            component={RecruitmenCandidatesConsent}
          />
          <Route exact path={`${lang}/*`} component={NotFound} />
        </Switch>
      </CareersContextProvider>
    </Router>
  );
}

export default App;
