import i18next, { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { CgScrollV } from 'react-icons/cg';
import Resources from '../../Resources';
const dateData = [
  {
    year: '2022',
    content: {
      image: Resources.timeline.Zaxe2022,
      header: {
        tr: new Date().getFullYear() === 2022 ? 'Bugün' : '2022',
        en: new Date().getFullYear() === 2022 ? 'Today' : '2022',
      },
      description: {
        tr: 'Z3 ve X3 modellerimizin piyasaya sürülmesi ile, Zaxe, 50’den fazla çalışanı olan ve 3 boyutlu yazıcıları 12’den fazla ülkede bulunan bir marka.',
        en: 'With the releases of X3 and Z3, Zaxe is a company with over 50 employees. You can find our printers in 12 countries and we are working on new products to innovate the 3D printing technology.',
      },
    },
  },
  {
    year: '2021',
    content: {
      image: Resources.timeline.Zaxe2021,
      header: { tr: 'Global Pazara Açılış', en: 'Zaxe Goes Global' },
      description: {
        tr: 'Türkiye’de birçok pazarda üstünlük elde ettikten sonra, Zaxe yurtdışına açılmaya başladı ve birçok ülkeye 3 boyutlu yazıcı satımı başladı.',
        en: 'Zaxe 3D Printing starts dominating certain industries in its home country like education and starts making bigger moves to open up to the global market.',
      },
    },
  },
  {
    year: '2020',
    content: {
      image: Resources.timeline.Zaxe2020,
      header: { tr: 'Gelişme Dolu Bir Yıl', en: 'A Year of Growth' },
      description: {
        tr: 'Z1’in piyasaya sürülmesi ile Zaxe tüm hızıyla büyümeye devam etti ve 40 milyon TL üzerinde değerlendirilerek sektördeki yerini kanıtladı.',
        en: 'Zaxe experiences exponential growth with the release of its new flagship model Z1 and in turn receives a valuation of 40 million TL in its sixth year.',
      },
    },
  },
  {
    year: '2019',
    content: {
      image: Resources.timeline.Zaxe2019,
      header: {
        tr: 'Z Serisi ile Daha Büyük ve Daha İyi',
        en: 'Bigger and Better with Z Series',
      },
      description: {
        tr: 'Zaxe tamamen yeni bir ürün serisi geliştirdi. Zaxe Z Serisi, X serisine nazaran daha büyük ve daha endüstriyel bir 3 boyutlu yazıcı olarak piyasaya sürüldü.',
        en: "Zaxe introduces a completely new product line. The development stage of Z1 ends and it's released as a bigger and more industrial alternative to the X series.",
      },
    },
  },
  {
    year: '2018',
    content: {
      image: Resources.timeline.Zaxe2018,
      header: { tr: 'xDesktop Yayınlandı', en: 'xDesktop is Live' },
      description: {
        tr: 'Zaxe, uzun süre üzerinde çalıştığımız kullanıcı dostu bir slicer olarak geliştirilen xDesktop’ı bitirerek, Zaxe 3 Boyutlu Yazıcılar ile birlikte kullanılması için yayınladı.',
        en: 'Zaxe finalizes the development of its own software. xDesktop becomes the default slicer for all Zaxe 3D printers with its user-friendly interface and multi-point access controlling capabilities.',
      },
    },
  },
  {
    year: '2017',
    content: {
      image: Resources.timeline.Zaxe2017,
      header: { tr: 'X1+ Piyasaya Sürüldü', en: 'X1+ is Released' },
      description: {
        tr: 'Zaxe, 300 x 300 x 300mm basım alanı ile, X1’in geliştirilmiş versiyonu olan X1+ 3 Boyutlu Yazıcısını piyasaya sürdü.',
        en: 'Zaxe 3D Printing develops and launches an improved version of X1 with a considerably bigger printing volume of 300 x 300 x 300mm under the name of X1+.',
      },
    },
  },
  {
    year: '2016',
    content: {
      image: Resources.timeline.Zaxe2016,
      header: { tr: 'İlk Yazıcımız, X1', en: 'Our First 3D Printer, X1' },
      description: {
        tr: 'X1, kullanıcılarımızdan pozitif tepkiler alarak raflarda yerini almaya başladı. İlk yazıcımız, 93 farklı model arasından “En İyi 3 Boyutlu Yazıcı” ödülünü alarak kalitesini kanıtladı.',
        en: 'X1 hits the shelves to overwhelmingly positive reception. The model receives the honor of being named the best 3D printer among 93 other candidates.',
      },
    },
  },
  {
    year: '2015',
    content: {
      image: Resources.timeline.Zaxe2015,
      header: { tr: 'Zaxe Kuruldu', en: 'Zaxe is Founded' },
      description: {
        tr: 'Zaxe, Baki Gezgen tarafından İstanbul’da kuruldu. Organizasyonumuz zaman kaybetmeden ilk 3 boyutlu yazıcımız olan Zaxe X1 üzerinde çalışmaya başladı.',
        en: 'Zaxe 3D Printing is founded in Istanbul by Baki Gezgen. The company immediately starts working on its first-ever 3D printer model named Zaxe X1.',
      },
    },
  },
];

export default function DateSlider() {
  const [selectedDate, setSelectedDate] = useState(0);
  const [disableScroll, setDisableScroll] = useState(false);

  useEffect(() => {
    const dateBar = document.querySelector('.date-bar');
    const dateBarMobile = document.querySelector('.date-bar-mobile');
    dateBar.scrollTo({
      top:
        document.querySelector(`.date-bar-button-${selectedDate}`).offsetTop -
        22,
      behavior: 'smooth',
    });
    dateBarMobile.scrollTo({
      left:
        document.querySelector(`.date-bar-mobile-button-${selectedDate}`)
          .offsetLeft - 50,
      behavior: 'smooth',
    });

    window.addEventListener(
      'resize',
      () =>
        setTimeout(() => {
          dateBar.scrollTo({
            top:
              document.querySelector(`.date-bar-button-${selectedDate}`)
                .offsetTop - 22,
            behavior: 'smooth',
          });
          dateBarMobile.scrollTo({
            left:
              document.querySelector(`.date-bar-mobile-button-${selectedDate}`)
                .offsetLeft - 50,
            behavior: 'smooth',
          });
        }),
      1000
    );
  }, [selectedDate]);

  useEffect(() => {
    disableScroll && setTimeout(() => setDisableScroll(false), 400);
  }, [disableScroll]);

  return (
    <div className='font-mark  w-full flex !overflow-hidden justify-center content-start p-0 m-0 bg-white'>
      <div className='flex justify-center w-full p-0 m-0 '>
        <div
          id='who-we-are'
          className='w-full !py-0 xl:p-0 lg:p-0 md:p-10 p-0 max-w-5xl overflow-hidden grid grid-cols-1 justify-items-start-center place-items-start content-start m-0 gap-10'>
          <h1 className='xl:p-0 lg:p-0 md:p-0 p-10 !pb-0 xl:text-3xl lg:text-3xl text-2xl text-zaxe font-extrabold'>
            {t('pages.homepage.sections.story-over-years.header')}
          </h1>
          <div className='relative xl:flex lg:flex md:flex hidden w-full min-h-[40rem] flex-wrap justify-center content-start'>
            <div
              onScroll={(e) => {
                const indicator = document.querySelector('.scroll-indicator');
                if (window.innerWidth >= 768 && !disableScroll) {
                  document
                    .querySelectorAll('.date-bar-button')
                    .forEach((button, x) => {
                      if (
                        parseInt(button.getBoundingClientRect().bottom + 5) >
                          parseInt(indicator.getBoundingClientRect().bottom) &&
                        parseInt(button.getBoundingClientRect().bottom - 5) <
                          parseInt(indicator.getBoundingClientRect().bottom) &&
                        x !== selectedDate
                      ) {
                        setSelectedDate(x);
                      }
                    });
                }
              }}
              className='w-[10%] max-h-[20rem] transition-all duration-500 date-bar overflow-x-hidden overflow-y-scroll hide-scrollbar grid grid-cols-1 justify-center place-content-start h-full relative'>
              <div className='h-[1.5rem]'></div>
              {dateData.map((date, index) => (
                <button
                  key={`desktop-date-button-${index}`}
                  onClick={() => {
                    setDisableScroll(true);
                    setSelectedDate(index, () => setDisableScroll(false));
                  }}
                  className={`${
                    index === selectedDate && 'scale-125  !text-zinc-700'
                  } transition-all date-bar-button date-bar-button-${index} duration-200 text-zinc-400 font-light text-xl`}>
                  {date.year}
                </button>
              ))}
              <div className='h-[17rem]'></div>
            </div>
            <div className='w-[90%] overflow-visible z-[11] min-h-[30rem] relative'>
              {dateData.map((date, index) => (
                <div
                  key={`desktop-date-content-${index}`}
                  className={`${
                    index === selectedDate && '!opacity-100'
                  } bg-cover opacity-0 transition-all duration-1000 bg-no-repeat bg-center w-full h-full absolute top-0 left-0`}
                  style={{
                    backgroundImage: `url('${date.content.image}')`,
                  }}>
                  <div className='w-full overflow-hidden h-full flex flex-wrap content-end justfiy-start p-10 text-white bg-black bg-opacity-[0.60]'>
                    <div className=' z-[3] text-white'>
                      <h1
                        className={` xl:text-3xl lg:text-3xl text-2xl font-extrabold`}>
                        {date.content.header[i18next.language]}
                      </h1>
                      <p
                        className={`xl:text-lg lg:text-lg w-full max-w-lg text-md text-white font-light`}>
                        {date.content.description[i18next.language]}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='absolute z-[1] left-0 pointer-events-none top-0 h-full w-[10%] min-h-[40rem]'>
              <div className='absolute top-0 left-0 w-full h-[50%] pointer-events-none bg-gradient-to-t from-[rgba(255,255,255,1.0)] to-[rgba(255,255,255,0.3)]'>
                <div className='relative scroll-indicator top-[3.2rem] h-px w-[100%] left-0 bg-zinc-300 p-0 m-0'>
                  <CgScrollV className='absolute text-2xl translate-x-full -translate-y-1/2 right-full top-1/2 text-zinc-400' />
                </div>
              </div>
              <div className='absolute bottom-0 w-full h-[50%] bg-white'></div>
            </div>
          </div>

          <div className='flex content-start justify-start w-full xl:hidden lg:hidden md:hidden'>
            <div className='w-full overflow-visible z-[2] min-h-[30rem] relative'>
              <div className='w-full absolute pt-5 left-0 top-0 z-[10] p-0 xl:hidden lg:hidden md:hidden flex flex-wrap justify-center content-start'>
                <div className='pl-3 w-[90%] z-[3] hide-scrollbar touch-pan-x scroll-smooth date-bar-mobile gap-x-7 flex justify-start content-center p-0 overflow-hidden overflow-x-scroll'>
                  {dateData.map((date, index) => (
                    <button
                      key={`mobile-date-button-${index}`}
                      onClick={() => {
                        setDisableScroll(true);
                        setSelectedDate(index, () => setDisableScroll(false));
                      }}
                      className={`${
                        index === selectedDate && '!scale-150 !opacity-100'
                      } transition-all date-bar-mobile-button-${index} opacity-50 duration-200 text-white font-light text-xl`}>
                      {date.year}
                    </button>
                  ))}
                  <div className='min-w-[70%] w-full max-w-[50%] h-2'></div>
                </div>
              </div>
              {dateData.map((date, index) => (
                <div
                  key={`mobile-date-content-${index}`}
                  className={`${
                    index === selectedDate && '!opacity-100'
                  } bg-cover opacity-0 transition-all duration-1000 bg-no-repeat bg-center w-full max-h-[32rem] h-screen absolute top-0 left-0`}
                  style={{
                    backgroundImage: `url('${date.content.image}')`,
                  }}>
                  <div className='w-full overflow-hidden h-full flex flex-wrap content-end justfiy-start p-5 py-14 text-white bg-black bg-opacity-[0.60]'>
                    <div className=' z-[3] text-white'>
                      <h1
                        className={`xl:text-3xl lg:text-3xl text-2xl font-extrabold`}>
                        {date.content.header[i18next.language]}
                      </h1>
                      <p
                        className={`xl:text-md lg:text-md w-full max-w-sm text-xs text-white font-light`}>
                        {date.content.description[i18next.language]}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
