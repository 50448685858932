import { t } from "i18next";
import React from "react";

import Resources from "../../Resources";

export default function HomeFooter() {
  return (
    <div
      className="font-mark w-full flex flex-wrap justify-center content-center p-0 m-0 bg-cover bg-center"
      style={{
        backgroundImage: `url('${Resources.Banner}')`,
      }}
    >
      <div className="w-full h-full bg-black bg-opacity-60 py-64 flex flex-wrap justify-center content-center">
        <div className="w-full max-w-5xl grid grid-cols-1 justify-items-start place-content-center gap-y-5 p-10">
          <h1 className="xl:text-3xl lg:text-3xl text-2xl text-white font-extrabold">
            {t("pages.homepage.sections.home-footer.header")}
          </h1>
          <p className="xl:text-md lg:text-md w-full max-w-[33rem] text-sm text-white font-normal">
            {t("pages.homepage.sections.home-footer.description")}
          </p>
        </div>
      </div>
    </div>
  );
}
