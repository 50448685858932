import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function Loader() {
  const { pathname } = useLocation();
  const [loaderClass, setLoaderClass] = useState(
    '!visible !opacity-100 !transition-all !duration-[0ms]'
  );

  useEffect(() => {
    setLoaderClass(
      '!visible !pointer-events-auto !cursor-wait !opacity-100 !transition-all !duration-[0ms]'
    );
    setTimeout(() => {
      setLoaderClass(
        '!invisible !pointer-events-none !cursor-auto !opacity-0 !transition-all !duration-1000'
      );
    }, 700);
  }, [pathname]);

  return (
    <div
      className={`${loaderClass} fixed loader-zaxe  fill-current font-mark !z-[99999999] !left-0 !top-0 !w-full min-w-[100vw] flex flex-wrap justify-center content-center bg-white text-zaxe text-4xl font-semibold h-full !min-h-[110vh]`}>
      <div className='p-0 flex flex-wrap justify-center content-center m-0'>
        <div className='rounded-full shadow-[0_0px_10px_#009bde7c] '>
          <svg
            className={`animate-spin h-5 w-5`}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'>
            <circle
              className='opacity-50'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'></circle>
            <path
              className='opacity-100'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
          </svg>
        </div>
      </div>
    </div>
  );
}
