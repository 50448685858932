import i18next, { t } from "i18next";
import React from "react";
import Contents from "../../Content";

export default function WhatWeOffer() {
  return (
    <div className="flex content-start justify-center w-full p-0 m-0 overflow-hidden bg-white font-mark">
      <div className="relative grid w-full max-w-5xl grid-cols-1 p-10 gap-y-20 bg-zinc-100">
        <div className="grid w-full grid-cols-1 justify-items-start gap-y-3">
          <h1 className="text-2xl font-extrabold xl:text-3xl lg:text-3xl text-zaxe">
            {t("pages.homepage.sections.what-we-offer.up-element.header")}
          </h1>
          <p className="w-full text-sm font-normal xl:text-md lg:text-md text-zinc-500">
            {t("pages.homepage.sections.what-we-offer.up-element.description")}
          </p>
        </div>
        <div className="grid w-full grid-cols-1 gap-5 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-y-10 justify-items-stretch">
          {Contents.whatWeOffer.columns.map((column, i) => (
            <div
              key={`wwo-${i}`}
              className="grid grid-cols-1 gap-5 justify-items-center place-content-start"
            >
              <div className="flex content-center justify-center text-5xl text-center text-zaxe">
                <img
                  width="100%"
                  alt={column.description.en}
                  src={column.icon}
                  className="h-full w-[3.5rem]"
                />
              </div>
              <div className="flex content-center justify-center text-xl text-center">
                <p className="w-full text-sm font-medium xl:text-md lg:text-md text-zinc-600">
                  {column.description[i18next.language]}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
